var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
    id: '1-8-3-49',
    data: {
      coupon_type: _vm.targetCouponInfo.couponType,
      is_add: _vm.isSatisfied ? 0 : 1
    }
  }),expression:"{\n    id: '1-8-3-49',\n    data: {\n      coupon_type: targetCouponInfo.couponType,\n      is_add: isSatisfied ? 0 : 1\n    }\n  }"}],staticClass:"coupon-filter"},[_c('div',{staticClass:"left-coupon-tip"},[_c('div',{ref:"couponTip",class:_vm.isOverflow ? 'coupon-tip-overflow' : 'overflow-line2'},[_c('div',{ref:"couponText",staticClass:"text",class:{ 'overflow-line2': _vm.isOverflow },domProps:{"innerHTML":_vm._s(_vm.couponTip)}}),_vm._v(" "),_c('s-popover',{ref:"popover",staticClass:"pop-icon",attrs:{"trigger-type":"click","placemen":"bottom","prop-style":_vm.couponFilterPopoverStyle,"content":_vm.popoverTip},model:{value:(_vm.showPop),callback:function ($$v) {_vm.showPop=$$v},expression:"showPop"}},[_c('Icon',{attrs:{"slot":"reference","name":"sui_icon_doubt_12px_2","size":"12px","color":"rgba(0, 0, 0, 0.6)"},slot:"reference"})],1)],1),_vm._v(" "),(_vm.showCountDown)?_c('div',{staticClass:"count-down"},[_c('span',{staticClass:"end-in"},[_vm._v(_vm._s(_vm.language.SHEIN_KEY_PWA_26614))]),_vm._v(" "),_c('count-down-time',{staticClass:"time",attrs:{"notReload":"","notHide":"","font-size":20/75 + 'rem',"time-stamp":Number(_vm.targetCouponInfo.end_date)}})],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"right-button"},[(!_vm.isSatisfied)?_c('div',{staticClass:"add-btn",on:{"click":_vm.handleAddOn}},[_c('SAdapterText',{attrs:{"min-size":"10","text":_vm.language.SHEIN_KEY_PWA_18957}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }