<template>
  <div class="embed-loading">
    <transition name="embed-loading__view">
      <div
        v-if="show"
        class="embed-loading__view"
        :style="{
          paddingTop,
          paddingBottom,
        }"
      ></div>
    </transition>
    <transition name="embed-loading__content">
      <s-loading
        v-if="show"
        show
        class="embed-loading__content"
        :style="{
          top: paddingTop,
        }"
      />
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
    top: {
      type: [Boolean, String],
      default: false
    },
    bottom: {
      type: [Boolean, String],
      default: false
    }
  },
  computed: {
    paddingTop() {
      return this.top === true ? '.2667rem' : this.top
    },
    paddingBottom() {
      return this.bottom === true ? '.2667rem' : this.bottom
    },
  },
}
</script>

<style lang="less">
.embed-loading {
  position: relative;

  &__view {
    box-sizing: content-box;
    height: 0.587rem;
  }

  &__content {
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__view-enter-active, &__view-leave-active {
    transition: height .3s, padding-top .3s, padding-bottom .3s;
  }

  &__view-enter, &__view-leave-to {
    height: 0;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  &__content-enter-active, &__content-leave-active {
    transition: transform .3s;
  }

  &__content-enter, &__content-leave-to {
    transform: scale(0);
  }

  .S-loading__common-inner {
    margin: 0 auto !important;
  }
}
</style>
