var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_setup.tags.length > 0),expression:"tags.length > 0"}],class:[
    'swiper-tag',
    {
      'swiper-tag_hide': _setup.isHide,
    }
  ],style:({
    ..._vm.themeVars
  })},[_c('div',{ref:"containerRef",staticClass:"swiper-tag__container",style:({
      '--duration': `${_vm.duration}ms`,
      width: _setup.width ? `${_setup.width}px` : undefined,
      height: _setup.height ? `${_setup.height}px` : undefined,
    })},[_vm._l((_setup.tags),function(tag,i){return [_c('Transition',{key:i,attrs:{"name":!_setup.isHide && _setup.flag ? 'slide' : ''},on:{"enter":_setup.onEnter}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(i === _setup.index),expression:"i === index"}],class:[
            'swiper-tag__item',
            {
              'swiper-tag__item_ltr': _vm.stretchDirection === 'ltr',
              'swiper-tag__item_rtl': _vm.stretchDirection === 'rtl',
              'swiper-tag__item_quick-ship': tag.tagType === 'quickShip',
            }
          ]},[(tag.icon)?_c(_setup.Icon,{staticClass:"swiper-tag__icon",attrs:{"name":tag.icon,"is-responsive-name":"","size":"12px"}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"swiper-tag__text"},[_vm._v("\n            "+_vm._s(tag.text)+"\n          ")])],1)])]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }