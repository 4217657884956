export default {
  bind: function (el, binding) {
    el.clickAwayEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event)
      }
    }
    document.body.addEventListener('click', el.clickAwayEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickAwayEvent)
  }
}
