<template>
  <div
    v-if="showPromotion"
    class="full-type-group j-full-add-on-header header-platform"
    :class="{'j-top-add-on': isSingleMall && actionBtnType == 'Pick' && !item }"
  >
    <div
      class="cart_item-header  item-header__add-on ele-wrapper"
    >
      <section
        class="cart_item-banner"
        tabindex="0"
        :class="{'no-border': showCondition == 'picked', 'is-single-mall': isSingleMall}"
      >
        <div class="title-action-operation">
          <i
            class="promotion-item-icon item-content-icon"
          >
            <sui_icon_activity_18px size="18px" />
          </i>
          <div
            class="promotion-tip-time"
            :class="{'is-single-mall': isSingleMall}"
          >
            <!-- 提示 -->
            <p
              class="cart_item-desc"
              v-html="AddBuyAllTip"
            >
            </p>
            <!-- 倒计时 -->
            <div
              v-if="integrityPromotionInfo.is_count_down == 1"
              class="client-only-height down-time"
            >
              <ClientOnly>
                <count-down-time :time-stamp="integrityPromotionInfo.end_time" />
              </ClientOnly>
            </div>
          </div>
          <!-- Pick/Repick -->
          <div
            v-show="actionBtnType == 'Pick'"
            class="cart_item-action"
            @click="handleAddBuyAll"
          >
            <span>{{ item ? language.SHEIN_KEY_PWA_15831 : language.SHEIN_KEY_PWA_15830 }}</span>
            <sui_icon_more_right_12px
              size="12px"
              :is-rotate="locals.GB_cssRight"
            />
          </div>
          <!-- Add -->
          <div
            v-show="actionBtnType == 'Add'"
            class="cart_item-action"
            @click="handleOpenSupplement"
          >
            <span>{{ language.SHEIN_KEY_PWA_15091 }}</span>
            <sui_icon_more_right_12px
              size="12px"
              :is-rotate="locals.GB_cssRight"
            />
          </div>
        </div>
      </section>
    </div>
    <!--   吸顶元素高度占位     -->
    <div class="j-top-act-height"></div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import ClientOnly from 'vue-client-only'
import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '1-8-5' })
import { isObject } from '@shein/common-function'
import { sui_icon_more_right_12px, sui_icon_activity_18px } from '@shein-aidc/icon-vue2'

export default {
  name: 'AddOnTop',
  components: {
    ClientOnly,
    sui_icon_more_right_12px,
    sui_icon_activity_18px
  },
  props: {
    integrityPromotionInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    // 是否单mall 单mall展示在所有商品顶部位置，多mall展示在mall下
    isSingleMall: {
      type: Boolean,
      default: true
    },
    showCondition: {
      type: String,
      default: '' // 单mall：notPick picked
    },
  },
  computed: {
    ...mapState('cart', ['language', 'cartAbtInfo', 'soldoutIds', 'locals']),
    ...mapGetters('cart', ['iconPrefix', 'cartList']),
    showPromotion(){
      return this.showCondition ? ((this.showCondition == 'notPick' && !this.item) || (this.showCondition == 'picked' && this.item)) : true
    },
    promotionContent(){
      return this.integrityPromotionInfo.content || {}
    },
    item () {
      let onSaleList = this.cartList.filter(item => this.soldoutIds.indexOf(item.id) == -1)
      const isPicked = onSaleList.some(item => item.product?.product_promotion_info?.some(p => p.promotion_product_type == '2' && p.promotion_id == this.integrityPromotionInfo.promotion_id))
      return isPicked
    },
    isChecked() {
      let onSaleList = this.cartList.filter(item => this.soldoutIds.indexOf(item.id) == -1)
      const isPicked = onSaleList.some(item => item.product?.product_promotion_info?.some(p => p.promotion_product_type == '2' && p.promotion_id == this.integrityPromotionInfo.promotion_id && item.is_checked))
      return isPicked
    },
    addBuyAllId () {
      return this.integrityPromotionInfo.promotion_id
    },
    actionBtnType () {
      if (this.promotionContent.action == 2) {
        return 'Pick'
      } else {
        return 'Add'
      }
    },
    AddBuyAllTip () {
      const tip = this.promotionContent.tip
      return isObject(tip) ? tip[this.isChecked ? 1 : 0] : tip
    },
    showAddOnList(){
      return this.promotionContent.action == 1
    },
  },
  watch: {
    actionBtnType(v){
      if(v == 'Add'){
        if($('.j-full-add-on-header .ele-wrapper').hasClass('sticky-block')){
          $('.j-full-add-on-header .ele-wrapper').removeClass('sticky-block')
        }
      }
    }
  },
  mounted () {
    daEventCenter.triggerNotice({
      daId: '1-8-5-1',
      extraData: {
        id: this.addBuyAllId,
        typeId: 22,
        isFullPromotion: this.actionBtnType == 'Add' ? 1 : 0,
        is_member_gift: 0,
      }
    })
  },
  methods: {
    ...mapMutations('cart', ['changeAppendageDrawerStatus', 'updateState']),
    handleAddBuyAll () {
      if (!this.addBuyAllId) return
      this.changeAppendageDrawerStatus({ status: 'addBuyShow', promotionId: this.addBuyAllId })
      
      daEventCenter.triggerNotice({
        daId: '1-8-5-2',
        extraData: {
          id: this.addBuyAllId,
          typeId: 22,
          isFullPromotion: this.actionBtnType == 'Add' ? 1 : 0,
          is_member_gift: 0,
        }
      })
    },
    handleOpenSupplement () {
      if (!this.addBuyAllId) return
      daEventCenter.triggerNotice({
        daId: '1-8-5-2',
        extraData: {
          id: this.addBuyAllId,
          typeId: 22,
          isFullPromotion: this.actionBtnType == 'Add' ? 1 : 0,
          is_member_gift: 0,
        }
      })

      this.handleAddOn({ promotion_id: this.addBuyAllId })
      
    },
    handleAddOn(promotion) {
      if (!promotion || !promotion.promotion_id) return
      this.updateState({
        key: 'addOnInfo', 
        value: {
          show: true,
          promotionId: promotion.promotion_id,
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.item-header__add-on{
 padding: 32/75rem 24/75rem 0;
  margin: 20/75rem 0 0;
  &.sticky-block{
    width: 100%;
    margin: 0;
    padding: 0;
    left: 0;
  }
}
</style>
