<template>
  <div
    v-show="incentiveBtnType "
    class="button-incentive-text"
  >
    <Icon
      :name="content.iconName"
      is-responsive-name
      size="12px"
    />
    <span
      :class="[
        'incentive-text',
        incentiveBtnType == 'flash_sale' ? 'incentive-text-flash': ''
      ]"
    >
      {{ language[content.text] }}
    </span>
  </div>
</template>

<script setup>
import { defineExpose, computed, defineProps } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { useStore } from '../../utils/index.js'
import { BUTTON_CONFIGS } from './incentiveTools.js'

const props = defineProps({
  controlCheckoutNameText: {
    type: String,
    default: ''
  }
})

const store = useStore()

const incentiveBtnType = computed(() => {
  if(props.controlCheckoutNameText) return ''
  return store.getters['cart/incentiveBtnType']
})

const language = computed(() => {
  return store.state.cart.language
})

const content = computed(() => {
  return BUTTON_CONFIGS[incentiveBtnType.value] || {}
})

defineExpose({
  content,
  incentiveBtnType
})

</script>

<style scoped lang="less">
.button-incentive-text {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4/75rem;

  max-width: 268/75rem;
  overflow: hidden;
  .incentive-text {
    color: #fa6338;
    font-size: 11px;
    font-weight: 510;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .incentive-text-flash {
    color: #facf19;
  }
}
</style>
