export function updateSessionOrderCache(billno) {
  if(!billno) return
  let showedOrders = sessionStorage.getItem('showedOrders')
  showedOrders = JSON.parse(showedOrders)
  if(Array.isArray(showedOrders)) {
    if(showedOrders.filter(i => i == billno)?.length == 0) showedOrders.push(billno)
  } else {
    showedOrders = [ billno ]
  }
  sessionStorage.setItem('showedOrders', JSON.stringify(showedOrders))
}