<template>
  <div
    class="preferred-seller-icon"
  >
    <img :src="`${PUBLIC_CDN}/pwa_dist/images/cart/sui_icon_preferred_seller-9f0612ec30.png`" />
  </div>
</template>

<script>
const { PUBLIC_CDN } = gbCommonInfo
export default {
  name: 'PreferredSellerStoreIcon',
  data() {
    return {
      PUBLIC_CDN
    }
  }
}
</script>

<style lang="less" scoped>
.preferred-seller-icon {
  display: inline-flex;
  vertical-align: top;
  width: 32/75rem;
  height: 32/75rem;
  margin-right: 6/75rem;
  img{
    width: 100%;
  }
}
</style>
