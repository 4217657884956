<script lang="jsx">
import cartMixin from '../../../mixins/cart-mixin'
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import CartStepper from './../CartStepper.vue'
import CartItemEditor from './CartItemEditor.vue'
import CartItemProductTag from './CartItemProductTag.vue'
import {
  handleOrderOrSingleIsMeetOrOver,
  animationDelete,
  getCartItemIndex,
  isValidCartItem,
} from '../../../utils/index'
import { withExpose } from '../../../utils/cartExpose'
import { 
  deleteCartItem, 
  batchWishGoods,
  fetchProductRecommend,
} from '../../../utils/fetcher'
import { PromotionUtils } from 'public/src/pages/common/promotion/utils'
import { getProductDetailUrl } from 'public/src/pages/goods_detail/utils/common.js'

daEventCenter.addSubscriber({ modulecode: '1-8-3' })
daEventCenter.addSubscriber({ modulecode: '2-24' })

/* Component */
import CartItemCheckbox from './CartItemCheckbox.vue'
import CartItemImgInfo from './CartItemImgInfo'
import CartItemPrice from './CartItemPrice'
import CartItemStore from '../newCartItem/CartItemStore.vue'
import CartReducePriceIcon from './CartReducePriceIcon.vue'
import CartItemSimilar from './CartItemSimilar.vue'
import SimilarInstance from 'public/src/pages/common/similar_product_modal/index.js'
import BehaviorLabel from 'public/src/pages/cartNew/utils/behaviorLabel.js'
import CustomDetailEntry from './CustomDetailEntry.vue'
import EstimatedTag from './EstimatedTag.vue'
import { getMainImageUrl } from 'public/src/pages/cartNew/utils/index.js'
import { isShowEstimated } from '@/public/src/pages/cartNew/utils/estimated.js'

import fastdom from 'fastdom'
import { markPoint } from 'public/src/services/mark/index.js'
import { transformImg } from '@shein/common-function'

import useDeleteTips from '../../../components/deleteTips/hooks/useDeleteTips'

let staticIndex = -1
const RECOMMEND_ITEM_LIMIT = 3 // 只需要外漏3个

export default {
  name: 'CartItem',
  setup() {
    const {
      showDeleteTips,
      getExtraData,
    } = useDeleteTips()
    return {
      showDeleteTips,
      getExtraData,
    }
  },
  components: {
    CartStepper,
    CartItemPrice,
    CartItemImgInfo,
    CartItemEditor,
    CartItemProductTag,
    CartReducePriceIcon,
    CustomDetailEntry,
    CartItemCheckbox,
    EstimatedTag,
  },
  mixins: [cartMixin],
  props: {
    item: {
      type: Object,
      default(){
        return {}
      }
    },
    // index: {
    //   type: Number,
    //   default: 0
    // },
    itemPromotionGroupInfo: {
      type: Object,
      default(){
        return {}
      }
    },
    isSSR: {
      type: Boolean,
      default: false
    },
    isInlineStore: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  data: function () {
    return {
      edit: {},
      isSwiperInit: false,
      itemSwiperEl: null,
      itemSwiperListener: null,
      // index: 0,
      // idx: -1,
      isFirstPage: false,
      exposedRows: [], // 存储已曝光的商品行元素id
      observer: null,
      recommendItems: [],
    }
  },
  computed: {
    ...mapState('cart', [
      'isPlatForm',
      'language',
      'soldoutIds',
      'batchActive',
      'cartAbtInfo',
      'cartInfo',
      'openedDeleteItemId',
      'loginStatus',
      'integrityPromotionInfo',
      'blackFridayImgGoods',
      'batchEditItemIds',
      'locals',
      'storeSelectsInfo',
      'cartsId',
      'lastBatchEditItem',
      'isCheckedByLocal',
      'filterType',
      'showIncentiveProductListDialog',
    ]),
    ...mapGetters('cart', [
      'memberLimit',
      'flashSaleGroupList',
      'showQuickShipTag',
      'underPriceGroupList',
      'iconPrefix',
      'underPriceAbtValue',
      'showOBMStore',
      'showThreeStore',
      'cartDiscountLogoA',
      'doFilter',
      'selectPrice',
      'switchOnEvoluSHEIN',
      'isNewCartDiscountStyle',
    ]),
    // batchEditCartItemIds: {
    //   get() {
    //     return this.batchEditItemIds
    //   },
    //   set(arr) {
    //     this.updateState({
    //       key: 'batchEditItemIds',
    //       value: arr || []
    //     })
    //   }
    // },
    isSoldOut() {
      return this.soldoutIds.indexOf(this.item.id) > -1
    },
    countDownData () {
      if (this.item.promotionTag.typeId === 4 && this.item.promotionTag.promotionLogoType === 8) { // 付费会员赠品
        const target = this.cartInfo.promotion_member_info?.find?.(f => f.promotion_id === this.item.promotionTag.promotionId)
        return target?.member_equity_end_time || ''
      }
      if (
        this.item.promotionTypeIds?.some(i => [3, 8, 11].includes(i)) ||
        (this.item.promotionTypeIds?.includes(10) && [1, 4, 5, 6].includes(Number(this.item.flashType)))
      ) {
        let promotion = this.item.product.product_promotion_info.find(item => [3, 8, 10, 11].includes(+item.type_id))
        if(promotion) {
          let targetPromotion = this.integrityPromotionInfo[promotion.promotion_id]
          if(targetPromotion?.is_count_down){
            return targetPromotion.end_time
          }
        }
      }
      if(this.item.product.insurable == 1 && this.item.product.insurable_time) {
        let end = this.item.product.insurable_time || 0
        if(end){
          end = (end + '').length >= 13 ? end : end * 1000
        }
        const now = (new Date()).getTime()
        const diffTime = end - now
        const diffDays = Math.floor(diffTime / 1000 / 60 / 60 / 24)
        return  diffDays < 3 ? this.item.product.insurable_time : ''
      }
      return ''
    },
    // 附赠品不支持编辑
    isAddBuyGoodsOrGiftGoods () {
      return this.item.product.product_promotion_info && this.item.product.product_promotion_info.some(promo => [1, 2].includes(+promo.promotion_product_type)) ? true : false
    },
    isNoEdit () {
      return this.isAddBuyGoodsOrGiftGoods || !this.isValid
    },
    isValid () {
      return isValidCartItem(this.item, this.isPlatForm)
    },
    attrLocationCorrespond () {
      const item = this.item
      let result = ''
      if (item && item.attr_value_location && item.attr_value_location.size_rule_list && item.attr_value_location.size_rule_list.length > 0) {
        item.attr_value_location.size_rule_list.forEach(locationsize => {
          if (locationsize.name == item.attr_value_en) {
            result = locationsize.correspond
          }
        })
      }
      return result
    },
    editorStatus () {
      const { item, soldoutIds } = this
      let result = ''
      if (soldoutIds.includes(item.id)) { // 售罄商品
        if (
          item.isSheinClubGiftItem ||
          this.isValid
        ) {
          result = 'reselect'
        } else {
          result = 'find-similar'
        }
      } else {
        const localSize = item.product && item.product.attr_value_std_name
        const trueSize = item.attr?.attr_value
        const attrLocationCorrespond = this.attrLocationCorrespond
        const moreImgs = item.product.color_image && item.relatedProductIds?.length > 0
        if (localSize || trueSize || attrLocationCorrespond || moreImgs || this.skuSaleAttrText) {
          result = 'editor'
        } else {
          result = ''
        }
      }
      return result
    },
    promotionGroupInfo(){
      let groupPromotion = this.item.product.product_promotion_info?.find(promotion => promotion.type_id == 10 || promotion.type_id == 30) // 常规闪购活动支持跨店铺or一口价
      if(groupPromotion) {
        let groupName = [10, 11].includes(groupPromotion.type_id) ? 'flashSaleGroupList' : 'underPriceGroupList'
        let groupPro = this.integrityPromotionInfo[groupPromotion.promotion_id] || {}
        return { ...groupPro, list: this[groupName][groupPromotion.promotion_id] || [] }
      }
      return this.itemPromotionGroupInfo
    },
    // 单品或订单限购满足数量或超过 置灰添加按钮
    isOrderOrSingleMeetOrOver(){
      let result = handleOrderOrSingleIsMeetOrOver(this.item, this.promotionGroupInfo, false)
      return Boolean(result)
    },
    //限时折扣
    isLimitProduct(){
      let promotion = this.item.product.product_promotion_info?.find(item => item.type_id == 3)
      if(!promotion) return false
      const { promotion_id = '' } = promotion
      const promotionInfo = this.integrityPromotionInfo[promotion_id] || {}
      let { single } = promotionInfo.cart_limit || {}
      return single && this.item.quantity >= single
    },

    disabledAdd: function (){
      return this.soldoutIds.includes(this.item.id) || this.isOrderOrSingleMeetOrOver || this.item.quantity >= 99 || this.isLimitProduct || (parseInt(this.item.quantity) >= parseInt(this.item.realTimeInventory))
    },
    promotionCutPriceShow () {
      if (this.item && this.item.reduce_price && this.item.is_checked != 1) {
        return this.item.reduce_price.siteFrom == this.SiteUID && this.item.reduce_price.reduceAmount.amount > 0
      }
      return false
    },
    reducePriceTip () {
      return this.template(`<b>${this.item.reduce_price?.reduceAmount?.amountWithSymbol || '$2.00'}</b>`, this.language.SHEIN_KEY_PWA_16323)
    },
    // 是否展示色块图
    isShowColorImg() {
      return this.item.product.main_sale_attr_show_mode == 2 && this.item.product.color_image && this.item.product.relatedColor?.length > 0
    },
    skuSaleAttrText () {
      if (!this.item.product?.sku_sale_attr?.length) return ''

      let result = []
      this.item.product.sku_sale_attr.forEach(item => {
        // 当存在色块图(color_image)时，颜色属性一定是主属性(is_main=1)
        if (this.isShowColorImg && item.is_main) {
          return result.push(` ${item.attr_value_name}`)
        }
        
        result.push(item.attr_value_name)
      })
      
      return result.join(' / ')
    },
    otherMallProductOnSale(){
      if(this.isPlatForm && this.item.mall_code_list?.length) {
        return true
      }
      return false
    },
    mallSaleOutStatus(){
      return this.otherMallProductOnSale && ((this.item.status == 1 || this.item.status == -1))
    },
    showQuickShip(){
      return this.showQuickShipTag && this.soldoutIds.indexOf(this.item.id) == -1 && this.item.quick_ship == 1 ? true : false
    },
    showFreeShipping() {
      return this.item.freight_free_rule_id > 0
    },
    showEvoluShein() {
      const { EVOLU_SHEIN_ID } = this.locals
      return this.switchOnEvoluSHEIN && this.item?.rules?.includes(EVOLU_SHEIN_ID)
    },
    blackFridayLabel() {
      return this.blackFridayImgGoods[this.item.product.goods_id]?.promoLabel || {}
    },
    /**
     * 赠品商品行是否置灰
     * is_checked 是否选中
     * isGiftItem 是否是赠品
     *   isBuyGiftItem 是否是买赠商品
     *   isSheinClubGiftItem 是否是[付费会员]满赠商品
     *   isFullGiftItem 是否是满赠商品
     *   isPartFullGiftItem 是否是部分满赠商品
     */
    giftItemDisable() {
      return !this.item.is_checked && this.item.isGiftItem
    },
    clubGiftItemDisable() {
      return !this.item.is_checked && this.item.isSheinClubGiftItem
    },
    baseCutImg() {
      if(!this.isFirstPage) return ''
      let url = getMainImageUrl(this.item, this.IS_RW)
      if (!this?.cutImg) {
        return url.replace(/^https?\:/, '').replace(/\b(\.jpg|\.gif|\.png)\b/, '.webp')
      }
      return this.cutImg(url, 750, { cutTo: 240, max: 240 })
    },
    showInlineRecommend() {
      return this.recommendItems?.length >= RECOMMEND_ITEM_LIMIT
    },
    goodsNameBeforeLabel() {
      return this.blackFridayImgGoods[this.item?.product?.goods_id]?.cartMobileBroadWiseView?.goodsNameLabel || {}
    },
    showSheinClubGiftTag() {
      return this.item.promotionTag?.typeId === 4 && this.item.promotionTag?.promotionLogoType === 8
    },
    // 是否显示券前价：在券筛选状态下且开启了abt值为beforecouponprice
    showBeforeCouponPrice() {
      return this.doFilter 
        && !isNaN(this.filterType)
        && this.selectPrice == 'beforecouponprice'
    },
    showEstimatedTag() {
      // 已失效商品/赠品/启券前价abt 不显示到手价标签
      if (this.isSoldOut || this.item.isGiftItem || this.showBeforeCouponPrice) return false
      // 未勾选到手价
      if (this.item.is_checked === 0) {
        return this.cartAbtInfo.CartNotCheckedEstimate?.param?.cart_notChecked_estimate === 'on'
          && this.item.per_coupon_discount?.length // 有优惠券的折扣
      }
      // 已勾选到手价
      return isShowEstimated(this.item)
    },
    // 是否在到手价标签上展示折扣信息：到手价标签展示 && 开启折扣标签新样式abt && 当前商品行有折扣信息
    showDiscountOnEstimated() {
      return this.showEstimatedTag && this.isNewCartDiscountStyle && this.maxDiscount != 0
    },
    // 是否展示折扣标签：abt开启 && 当前商品行无到手价标签 && 当前商品行有折扣信息
    showDiscountTag() {
      return this.isNewCartDiscountStyle && !this.showEstimatedTag && this.maxDiscount != 0
    },
    // 当前商品行最大折扣信息
    maxDiscount() {
      if (this.item.isGiftItem) return 0
      const { unit_discount = 0, sale_discount = 0 } = this.item?.product
      return Math.max(Number(unit_discount), Number(sale_discount))
    },
    discountText() {
      const { localLang, GB_cssRight } = this.locals
      return GB_cssRight
        ? this.showDiscountOnEstimated
          ? (localLang != 'il' ? '-' : '') + '%' + this.maxDiscount
          : this.maxDiscount + '%' + (localLang != 'il' ? '-' : '')
        : (localLang != 'il' ? '-' : '') + this.maxDiscount + '%'
    },
    getTrackingDiscountData() {
      if (this.maxDiscount != 0) {
        // 0商品图片左上角；1商品行折扣标签；2到手价标签上
        const flag = this.isNewCartDiscountStyle
          ? this.showEstimatedTag ? '2' : '1'
          : '0'
        return `discount_${flag}_${this.maxDiscount}`
      }
      return null
    }
  },
  watch: {
    cartsId: {
      handler(newVal) {
        if (this.$isServer) return
        const idx = getCartItemIndex({ item: this.item, cartsId: newVal })
        if (idx === staticIndex) return
        this.handleIdxSetter()
        
      }
    },
    batchActive (n) {
      if (!n) {
        if (this.itemSwiperEl?.swiper) this.itemSwiperEl.swiper.allowTouchMove = true
        return
      }

      if (this.itemSwiperEl?.swiper) this.itemSwiperEl.swiper.allowTouchMove = false

      if (this.openedDeleteItemId === this.item.id) {
        this.itemSwiperEl?.swiper?.slideTo(0, 300, false)
      }
    },
    openedDeleteItemId (n){
      if(n && n !== this.item.id){
        this.itemSwiperEl?.swiper?.slideTo(0, 300, false)
      }
    }
  },
  activated() {
    this.swiperInit()
  },
  created() {
    if (this.cartsId.indexOf(this.item.id) < 6 && this.isFirstPage === false) {
      this.isFirstPage = true
    }
  },
  async mounted () {
    this.swiperInit()
    let { focusScroll } = await import('public/src/services/focusScroll/index.js')
    focusScroll.bindListener({ elementSelector: '.j-FS-cart-item-qty' })
    this.handleIdxSetter()

    this.initObserver()
  },
  beforeDestroy() {
    this.itemSwiperEl?.removeEventListener('swiperreachend', this.itemSwiperListener)
  },
  methods: {
    ...mapMutations('cart', ['updateState', 'handleModal', 'updateBatchEditItemIds']),
    ...mapActions('cart', ['handleCartLogin', 'asyncUpdateCartInfo']),
    ...mapMutations(['changeRootSLoadingStatus']),
    handleBatchActiveCheckedItem({ value = '', checked = false }) {
      console.log(value, checked)
      if (this.lastBatchEditItem[value] && checked) { // checkbox会触发两次change事件
        this.lastBatchEditItem = {}
        return
      }
      this.lastBatchEditItem[value] = checked
      daEventCenter.triggerNotice({
        daId: '1-8-7-28',
        extraData: {
          checked: checked,
        }
      })
    },
    handleIdxSetter() {
      const idx = getCartItemIndex({ item: this.item, cartsId: this.cartsId })
      staticIndex = idx
      fastdom.mutate(() => {
        this.$refs.cardGoodsItem.setAttribute('data-index', idx)
      })
      
    },
    /**
     * @description 不可参与优惠券提示
     1、自营商品
     2、have_tag为1命中
     3、abt返回on
     * @param {*} item 购物车商品属性
     */
    isShowNoCouponUse (item) {
      let flag = 'unused_coupon_tip_0'
      if (item?.store_type != 2) return flag
      if (item?.product?.have_tag == 1) {
        flag = `unused_coupon_tip_${
          this.cartAbtInfo?.['Cartnoteligibleforcoupons']?.param == 'on'
            ? 1
            : 2
        }`
      }
      return flag
    },
    getBehaviorText(item) {
      const label = this.blackFridayImgGoods[item?.product?.goods_id]?.showBehaviorLabel
      if (!label) return ''
      return BehaviorLabel.getSaText(label, this.language)
    },
    getOtherMark(item){
      let result = []
      if(item?.promotionTag?.typeId == 33) result.push('show_voucher')
      return result.join('|')
    },
    swiperInit() {
      if (!this.itemSwiperEl) this.itemSwiperEl = document.querySelector(`.j-cart-item-${this.item.id} swiper-container`)
      setTimeout(() => {
        this.itemSwiperEl.initialize()

        this.itemSwiperListener = (event) => {
          /**
           * 商品行内部的 swiper 也会触发 swiperreachend
           * 判断触发 swiperreachend 的 swiper 是否为 商品行的 swiper
           */
          if (event.target !== this.$refs.swiperContainer) return
          this.updateState({ key: 'openedDeleteItemId', value: this.item.id })
        }
        this.itemSwiperEl.addEventListener('swiperreachend', this.itemSwiperListener)

        // this.resizeSwiperBox()
      }, 0)
    },
    // 升级 swiper 后，不再需要动态计算 width/height，线上稳定运行后可删除
    // resizeSwiperBox() {
    //   this.$nextTick(() => {
    //     const itemHeight = $(`.j-cart-item-${this.item.id} .mshe-item-wrap`).height()
    //     if (itemHeight && itemHeight > 0) {
    //       const itemCount = $(`.j-cart-item-${this.item.id} .opertation-item`)?.length
    //       $(`.j-cart-item-${this.item.id} .j-operation-tab`).css({ width: `${120 / 75 * itemCount}rem` })
    //       $(`.j-cart-item-${this.item.id} .j-operation-tab`).css({ height: `${itemHeight}px` })
    //     }
    //   })
    // },
    verifyIsNewcomerItem() {
      if (this.item.isNewcomerItem) {
        this.$toast(this.language.SHEIN_KEY_PWA_26183)
        daEventCenter.triggerNotice({ daId: '1-8-3-34' })
        return true
      }
      return false
    },
    handleSave () {
      if (this.verifyIsNewcomerItem()) return
      daEventCenter.triggerNotice({ daId: '1-8-3-36' })
      this.handleModal({
        text: this.language.SHEIN_KEY_PWA_17954,
        okCallback: async () => {
          if (this.loginStatus) {
            this.handleAddWishlist()
          } else {
            const { result, res } = await this.handleCartLogin({ type: 'wishlist' })
            if(res.code == 0){
              await this.asyncUpdateCartInfo({ cartInfo: res.info })
              this.reflashGetCoupon()
              if (!result.equalCart) {
                this.$toast(this.language.SHEIN_KEY_PWA_17961, 5000)
              }
            }

          }
        },
        cancelCallback: () => {
          // 【埋点统一】收藏埋点的统一
          daEventCenter.triggerNotice({
            daId: '1-8-1-2',
            extraData: {
              result: 0,
              code: 'cart',
              mall_code: this.item.mall_code,
              postData: {
                action: 0, // 选中发1, 取消发0
                goods_id: this.item.product.goods_id,
                sku: this.item.product.goods_sn,
                detail: this.item.product,
                // index: this.index,
                index: staticIndex,
                quickShip: this.showQuickShip ? 1 : 0
              },
              from: 'cart'
            }
          })
        }
      })
    },
    handleAddWishlist () {
      const { id } = this.item
      this.changeRootSLoadingStatus({
        show: true,
        maskTransparent: true,
      })
      let ids = []
      ids.push(id)
      batchWishGoods({ ids })
        .then(async res => {

          // 【埋点统一】收藏埋点的统一
          daEventCenter.triggerNotice({
            daId: '1-8-1-2',
            extraData: {
              result: { data: { code: res.code } },
              code: 'cart',
              mall_code: this.item.mall_code,
              postData: {
                action: 1, // 选中发1, 取消发0
                goods_id: this.item.product.goods_id,
                sku: this.item.product.goods_sn,
                detail: this.item.product,
                index: staticIndex,
                quickShip: this.showQuickShip ? 1 : 0
              },
              from: 'cart'
            }
          })
          this.changeRootSLoadingStatus({
            show: false,
          })
          if (res?.code == 0) {
            this.saveItemAnimation(this.item.product.goods_img)
            await this.asyncUpdateCartInfo({ cartInfo: res.info })
            this.reflashGetCoupon()
          } else {
            if (res.code == -4 || res.code == -2) {
              this.$toast(this.language.SHEIN_KEY_PWA_17639)
            } else if (Number(res.code) === 400427 && res.info){
              this.$toast(this.template(res.info.limit, this.language.SHEIN_KEY_PWA_18556), 3000)
            } else {
              this.$toast(res.tips || this.language.SHEIN_KEY_PWA_17575)
            }
          }
        })
    },
    saveItemAnimation (img) {
      this.updateState({ key: 'saveItem', value: { img: img, show: false } })
      setTimeout(() => {
        this.updateState({ key: 'saveItem', value: { img: img, show: true } })
      }, 500)

      setTimeout( () => {
        this.updateState({ key: 'saveItem', value: { img: '', show: false } })
      }, 2000)
    },
    handleSlideDelete() {
      daEventCenter.triggerNotice({ 
        daId: '1-8-3-54',
        extraData: {
          goods_id: this.item?.product?.goods_id,
          cart_id: this.item?.id,
        }
      })
      this.handleDelete()
    },
    handleDelete (type) {
      if (this.batchActive) return false
      if(this.config?.disableSub) {
        this.$toast(this.language.SHEIN_KEY_PWA_31537)
        return
      }
      const item = this.item
      if (!this.soldoutIds.includes(this.item.id)) {
        // 展示新版删除弹窗
        const showDeleteTipsResult = this.showDeleteTips({
          cartAbtInfo: this.cartAbtInfo,
          item,
          language: this.language,
          isBatch: false,
          onUncheck: () => {
            this.$parent?.handleCheckedItem({ value: item.id })
          },
          onDelete: () => {
            this.delConfirm(type)
          },
          onMoveToWishList: async () => {
            if (this.loginStatus) {
              this.handleAddWishlist()
            } else {
              const { result, res } = await this.handleCartLogin({ type: 'wishlist' })
              if(res.code == 0){
                await this.asyncUpdateCartInfo({ cartInfo: res.info })
                this.reflashGetCoupon()
                if (!result.equalCart) {
                  this.$toast(this.language.SHEIN_KEY_PWA_17961, 5000)
                }
              }

            }
          },
          onDeleteAndFindSimilar: () => {
            this.delConfirm('showSimilar')
          },
          onClose: () => {
            this.itemSwiperEl?.swiper?.slideTo(0, 300, false)
          },
        })
        if (showDeleteTipsResult) return
      }
      const deletsTipsExtraData = this.getExtraData(this.item, 0)
      daEventCenter.triggerNotice({ daId: '1-8-3-37', extraData: deletsTipsExtraData })
      this.handleModal({
        text: this.language.SHEIN_KEY_PWA_15504,
        canceltext: this.language.SHEIN_KEY_PWA_15498,
        oktext: this.language.SHEIN_KEY_PWA_15502,
        okCallback: () => {
          this.delConfirm(type)
        },
        cancelCallback: () => {
          this.itemSwiperEl?.swiper?.slideTo(0, 300, false)
          daEventCenter.triggerNotice({ daId: '1-8-3-38', extraData: { result: 0, ...deletsTipsExtraData } })
        }
      })
    },
    handleShare() {
      this.updateState({
        key: 'showShareDraw',
        value: { 
          show: true, 
          params: {
            mode: 'goods_detail',
            items: [this.item]
          }
        }
      })
      daEventCenter.triggerNotice({
        daId: '2-24-2',
        bindData: {
          share_type: 'page',
          content_id: this.item.product.goods_id
        }
      })
    },
    handleFindSimilar() {
      daEventCenter.triggerNotice({
        daId: '1-8-3-27',
        extraData: {
          sale_status: 0,
          main_goods_id: this.item.product.goods_id,
        }
      })
      this.showSimilar()
      this.itemSwiperEl?.swiper?.slideTo(0, 300, false)
    },
    showSimilar(config) {
      SimilarInstance.showModal(this.item.product, config)
    },
    delConfirm(type) {
      this.changeRootSLoadingStatus({
        show: true,
        maskTransparent: true,
      })
      deleteCartItem({ item: this.item }).then(res => {
        this.changeRootSLoadingStatus({
          show: false,
        })
        const deletsTipsExtraData = this.getExtraData(this.item, 0)
        if (res.code == 0) {
          animationDelete(this.$refs.cardGoodsItem, () => {
            this.asyncUpdateCartInfo({ cartInfo: res.info })
          })
          daEventCenter.triggerNotice({ daId: '1-8-3-38', extraData: { result: 1, ...deletsTipsExtraData } })
          if (type === 'reduce') {
            daEventCenter.triggerNotice({ 
              daId: '1-8-3-40',
              extraData: {
                qty: this.item?.quantity,
                goods_type: this.item?.isGiftItem ? 'gift' : (this.item?.isAddItem ? 'add-on' : ''),
                scene: this.showIncentiveProductListDialog ? 'benefit_pop' : 'cart'
              }
            })
          }
          if (type === 'showSimilar') {
            this.showSimilar({ recAnalysis: { activeFrom: 'deleteproducts' }, isForceUpdate: true, isShowCustomHeader: false, drawerType: 'normal', desc: this.language.SHEIN_KEY_PWA_27232, showHalfModal: true, contentBgColor: '#F6F6F6' })
          }
        } else {
          daEventCenter.triggerNotice({ daId: '1-8-3-38', extraData: { result: 0, ...deletsTipsExtraData } })
        }
      }).catch(()=>{
        this.changeRootSLoadingStatus({
          show: false,
        })
      })
    },

    // 领券接口更新
    reflashGetCoupon () {
      this.updateState({
        key: 'isReflashGetCouponFetch',
        value: true
      })
    },


    async jumpDetail() {
      if ( this.batchActive ) return
      if (this.config?.disableJump) return
      const {
        mall_code,
        product: { goods_url_name, goods_id, cat_id, goods_img },
        isSheinClubGiftItem,
        isInvalid,
      } = this.item

      if (this.isSoldOut && isSheinClubGiftItem) {
        this.$toast(isInvalid ? this.language.SHEIN_KEY_PWA_31299 : this.language.SHEIN_KEY_PWA_31162)
        return
      }

      markPoint('toNextPageClick', 'public')
      this.clickGoodsList()
      this.$store.commit('addProductDetailBfCache', { ...this.item, ...{ goods_img } }, { root: true })
      const url = await getProductDetailUrl({
        imgSrc: goods_img,
        langPath: gbCommonInfo.langPath,
        goods_id,
        goods_url_name,
        cat_id,
        mall_code
      })
      this.$routerPush(url)
    },
    clickGoodsList() {
      daEventCenter.triggerNotice({
        daId: '1-8-3-3',
        extraData: {
          item: this.item,
          index: staticIndex,
          tspId: this.blackFridayLabel?.tagId || '',
          behaviorText: this.getBehaviorText(this.item) || '',
          goodsNameBeforeLabelId: this.goodsNameBeforeLabel.labelId,
        }
      })
    },

    async jump(promotionTag, url_prams = '', analysisData){
      if(typeof window != 'undefined' && !navigator.onLine) return
      if (this.config?.disableJump) return
      if (promotionTag.typeId === 4 && promotionTag.promotionLogoType === 8) {
        this.$router.push(`${gbCommonInfo.langPath}/user/prime?productsource=clubgiftcart`)
        return
      }
      let promotionItem = this.integrityPromotionInfo[promotionTag.promotionId] || {}
      promotionItem.scId = promotionItem?.goods?.sc_id || ''
      this.changeRootSLoadingStatus({
        show: true,
        maskTransparent: true,
      })
      const url = await PromotionUtils.promotionLink({
        ...promotionItem,
        url_prams,
        entry_from: 'entry`cart',
        src_module: analysisData?.data?.src_module || '',
        src_identifier: analysisData?.data?.src_identifier || '',
        src_tab_page_id: analysisData?.data?.src_tab_page_id || '',
      }, { item: this.item, IS_RW: this.IS_RW } )
      this.changeRootSLoadingStatus({
        show: false,
      })
      if(url){
        markPoint('toNextPageClick', 'public')
        this.$router.push(url)
      }
    },
    handleClickItemBatchCheck(e) {
      if (this.item.isNewcomerItem && e.target.className.includes('S-checkbox__input')) {
        this.$toast(this.language.SHEIN_KEY_PWA_26189)
        daEventCenter.triggerNotice({ daId: '1-8-3-35' })
        return
      }
    },
    handleItemBatchCheck({ value = '', checked = false } = {}) {
      const add = []
      const del = []
      if ( checked ) {
        add.push(value)
      } else {
        del.push(value)
      }
      this.updateBatchEditItemIds({ add, del })
    },
    isShowShareButton(item, cartAbtInfo, soldoutIds) {
      const isSubGoods = item?.product?.product_promotion_info && item.product.product_promotion_info?.some(promo => [1, 2].includes(+promo.promotion_product_type)) ? true : false
      const showShare = !(soldoutIds.indexOf(item.id) > -1) && !isSubGoods
      return showShare && !this.IS_RW
    },
    initObserver() {
      if (this.$isServer) return
  
      const exposeConfigs = []

      // 曝光请求售罄商品推荐
      if (this.cartAbtInfo.SheinFindSimilar?.param?.FindSimilarShow == 'Show' && this.editorStatus == 'find-similar') {
        exposeConfigs.push({
          el: this.$refs.cardGoodsItem,
          once: true,
          delay: 0,
          cb: () => {
            this.fetchRecommend()
          }
        })
      }

      // 曝光埋点相关
      exposeConfigs.push(...[
        {
          el: this.$refs.findSimilar,
          once: true,
          cb: () => {
            daEventCenter.triggerNotice({
              daId: '1-8-3-26',
              extraData: {
                main_goods_id: this.item.product.goods_id,
                sale_status: 0,
              }
            })
          }
        },
        {
          el: this.$refs.shareButton,
          once: true,
          cb: () => {
            daEventCenter.triggerNotice({
              daId: '2-24-1',
              bindData: [{
                share_type: 'page',
                content_id: this.item.product.goods_id,
              }]
            })
          }
        }
      ])
      
      withExpose(exposeConfigs)
    },
    async fetchRecommend() {
      let { recommendInfo } = await fetchProductRecommend(this.item.product)
      this.recommendItems = recommendInfo?.products?.slice(0, RECOMMEND_ITEM_LIMIT) || []
    },
    getScene() {
      if(this.config?.scene) return this.config.scene
      else return 'cart'
    }
  },
  inject: {
    cutImg: {
      from: 'cutImg',
      default: (url) => url,
    },
  },
  render () {
    const {
      item,
      language,
      batchActive,
      soldoutIds,
      isAddBuyGoodsOrGiftGoods,
      iconPrefix,
      editorStatus,
      isNoEdit,
      skuSaleAttrText,
      showQuickShip,
      showFreeShipping,
      countDownData,
      cartAbtInfo,
      promotionCutPriceShow,
      IS_RW,
      reducePriceTip,
      mallSaleOutStatus,
      blackFridayLabel,
      memberLimit,
      giftItemDisable,
      clubGiftItemDisable,
      isInlineStore,
      showInlineRecommend,
      isShowColorImg,
      goodsNameBeforeLabel,
      showSheinClubGiftTag,
      locals,
      showEvoluShein,
      config,
      showEstimatedTag,
    } = this

    // console.log('-----reRender ', this.idx)
    // console.log('-----reRender ', this.item.id)

    const isSoldOut = soldoutIds.indexOf(item.id) > -1

    /* 勾选框 */
    const renderCheckbox = () => {
      const isShow = !isSoldOut || batchActive
      if (!isShow) return null
      // batchEditItemIds
      return (
        <CartItemCheckbox
          item={item}
          batch-active={batchActive}
          batch-edit-item-ids={this.batchEditItemIds}
          is-checked-by-local={this.isCheckedByLocal}
          config={config}
        />
      )
    }
    /* 商品名 */
    const renderRightStruct = () => {
      const renderIcon = () => {
        const isShow = !batchActive && (!isAddBuyGoodsOrGiftGoods || isSoldOut) && !config?.hideSaveIcon
        if (isShow) {
          return (
            <i
              class={[
                'cart-save',
                'sui_icon_save_movetowishlist_18px',
                iconPrefix
              ]}
              v-enterkey
              tabindex={batchActive ? -1 : 0}
              role="button"
              aria-label={language.SHEIN_KEY_PWA_17955}
              vOn:click_stop={this.handleSave}
            ></i>
          )
        } else {
          return null
        }
      }
      /* 商品名前面的标签 */
      const renderGoodsNameBeforeLabel = () => {
        if (goodsNameBeforeLabel.icon) {
          return (
            <img
              src={transformImg({ img: goodsNameBeforeLabel.icon })}
              aria-hidden="true"
              class="goods-name__before-label"
            />
          )
        } else {
          return null
        }
      }
      return (
        <div
          class="right-struct"
          vOn:click={this.jumpDetail}
        >
          {renderGoodsNameBeforeLabel()}
          <span class="goods-name">
            {item.product.goods_name}
          </span>
          {renderIcon()}
        </div>
      )
    }
    /* 店铺名 */
    const renderCartItemStore = () => {
      return isInlineStore ? <CartItemStore item={item} /> : null
    }
    /* 商品标签 */
    const renderProductTag = () => {
      const isShow = !isSoldOut && (item.promotionTag && item.promotionTag.typeIdText || showQuickShip || showFreeShipping || item.multiPromotionTags?.length > 0 || showEvoluShein)
      if (isShow) {
        return (
          <CartItemProductTag
            language={language}
            show-quick-ship={showQuickShip}
            show-free-shipping={showFreeShipping}
            item={item}
            page="cart"
            batch-active={batchActive}
            abt-params={cartAbtInfo}
            time-stamp={countDownData}
            icon-prefix={iconPrefix}
            show-evolu-shein={showEvoluShein}
            vOn:jump={this.jump}
          />
        )
      } else {
        return null
      }
      
    }
    /* 降价提醒 */
    const renderReducePriceIcon = () => {
      const isShow = promotionCutPriceShow && !isSoldOut && !item.is_checked
      if (isShow) {
        return (
          <div class="reduce-price-tip">
            <CartReducePriceIcon is-rw={IS_RW} />
            <span domPropsInnerHTML={reducePriceTip}></span>
          </div>
        )
      } else {
        return null
      }
    }
    /* 数量有限 */
    const renderQuantityLimitTip = () => {
      const isShow = !(+item.realTimeInventory >= 10 || +item.realTimeInventory == 0 || item.product.is_on_sale == 0 || +item.realTimeInventory >= parseInt(item.quantity)) && editorStatus != 'reselect'
      if (isShow) {
        return (
          <div
            class="limit-quantity-tip few-inventory"
            domPropsInnerHTML={language.SHEIN_KEY_PWA_18590}
          ></div>
        )
      } else {
        return null
      }
    }
    const renderTips = () => {
      if (item.isNewcomerItem) {
        return (
          <div
            class="newcomer-tip"
            domPropsInnerHTML={language.SHEIN_KEY_PWA_26189}
          ></div>
        )
      }
      if (item.isLoseNewcomerItem) {
        return (
          <div
            class="newcomer-tip"
            domPropsInnerHTML={language.SHEIN_KEY_PWA_26188}
          ></div>
        )
      }

      if (isSoldOut && item.isSheinClubGiftItem) {
        return (
          <div
            class="newcomer-tip"
            domPropsInnerHTML={item.isInvalid ? language.SHEIN_KEY_PWA_31299 : language.SHEIN_KEY_PWA_31162}
          ></div>
        )
      }

      /* 其他颜色或尺码 */
      const isShow = editorStatus == 'reselect' && !isNoEdit
      if (isShow) {
        return (
          <div
            class="limit-quantity-tip"
            domPropsInnerHTML={mallSaleOutStatus ? language.SHEIN_KEY_PWA_20950 : language.SHEIN_KEY_PWA_15793}
          ></div>
        )
      } else {
        return null
      }
    }
    /* 删除 or 步进器 */
    const renderOperation = () => {
      const isShow = isSoldOut || isAddBuyGoodsOrGiftGoods
      if (isShow) {
        return (
          <i
            v-enterkey
            class={[
              'cart-item__deleteBtn',
              'sui_icon_delete_18px',
              iconPrefix,
              { unvisible: batchActive }
            ]}
            role="button"
            tabindex="0"
            aria-label={language.SHEIN_KEY_PWA_15502}
            vOn:click_stop={this.handleDelete}
          ></i>
        )
      } else {
        return (
          <CartStepper
            disabled-add={this.disabledAdd}
            item={item}
            vOn:handleDelete={type => this.handleDelete(type)}
            config={config}
          />
        )
      }
    }
    
    /* 删除滑块 */
    const renderDelele = () => {
      return (
        <div
          class="opertation-item delete"
          aria-hidden="true"
          vOn:click={this.handleSlideDelete}
        >
          <span class="opertation-text">{ language.SHEIN_KEY_PWA_15502 }</span>
        </div>
      )
    }

    const renderShare = () => {
      const showShare = this.isShowShareButton(this.item, this.cartAbtInfo, this.soldoutIds)
      if(showShare) return (
        <div
          class="opertation-item share"
          aria-hidden="true"
          ref="shareButton"
          vOn:click={this.handleShare}
        >
          <span class="opertation-text">{ language.SHEIN_KEY_PWA_14817 }</span>
        </div>
      )
      else return null
    }

    const renderSimilar = () => {
      const showSimilar = this.cartAbtInfo?.SheinFindSimilar?.param?.OnsaleSimilarShow == 'Show' && !isSoldOut
      if(showSimilar && !this.item.isNewcomerItem) return (
        <div
          ref="findSimilar"
          class="opertation-item similar"
          aria-hidden="true"
          vOn:click={this.handleFindSimilar}
        >
          <span class="opertation-text">{ language.SHEIN_KEY_PWA_16789 }</span>
        </div>
      )
      else return null
    }

    const renderAddWish = () => {
      if(showInlineRecommend) return (
        <div
          class="opertation-item wish"
          aria-hidden="true"
          vOn:click={this.handleSave}
        >
          <span class="opertation-text">{ language.SHEIN_KEY_PWA_26156 }</span>
        </div>
      )
      else return null
    }


    const renderEstimatedTag = () => {
      if (showEstimatedTag) return (
        <EstimatedTag
          item={item}
          language={this.language}
          show-discount={this.showDiscountOnEstimated}
          discount-text={this.discountText}
        />
      )  
    }

    const renderCartRight = () => {
      if (showInlineRecommend) {
        return (
          <CartItemSimilar
            cartItem={item}
            similarItems={this.recommendItems}
            vOn:handleMoreSimilar={this.showSimilar}
          />
        )
      } else {
        return (
          <div>
            <div
              class={[
                giftItemDisable && 'cart-item__disable',
                clubGiftItemDisable && 'cart-item__clubgift-disable'
              ]}
            >
              { renderRightStruct() }
              { renderCartItemStore() }
              <CartItemEditor
                ref="cartAttrBtn"
                index={0}
                editor-status={editorStatus}
                is-add-buy-goods-or-gift-goods={isAddBuyGoodsOrGiftGoods}
                is-no-edit={isNoEdit}
                item={item}
                sku-sale-attr-text={skuSaleAttrText}
                is-show-color-img={isShowColorImg}
                config={config}
              />
              { renderProductTag() }
              { renderReducePriceIcon() }
              { renderQuantityLimitTip() }
              { renderTips() }
            </div>
            <div
              class="cart-item__operation"
            >
              <CartItemPrice
                item={item}
                promotion-cut-price-show={promotionCutPriceShow}
                iconPrefix={iconPrefix}
                locals={this.locals}
                language={this.language}
                cart-discount-logo-a={this.cartDiscountLogoA}
                show-before-coupon-price={this.showBeforeCouponPrice}
                show-discount-tag={this.showDiscountTag}
                discount-text={this.discountText}
              >
                <template slot="operation">
                  { renderOperation() }
                </template>
              </CartItemPrice>
            </div>
            {/* 到手价标签 */}
            { renderEstimatedTag() }
          </div>
        )
      }
    }

    const renderCustomEntry = () => {
      const customInfo = item.product?.customization_info?.preview || {}
      const isCustomGood = Boolean(Object.keys(customInfo).length)
      const haveCustomData = customInfo?.images?.length || customInfo?.text?.length || customInfo?.effects?.length
      if(isCustomGood && haveCustomData && !isSoldOut && !IS_RW) return (
        <CustomDetailEntry item={item}></CustomDetailEntry>
      )
      else return null
    }

    return (
      <div
        ref="cardGoodsItem"
        class={[
          'cart-list-item',
          `j-cart-item-${item.id}`,
          { 'not-promotion-item': !item.promotionGroupId }
        ]}
        v-expose={{
          id: batchActive ? '' : '1-8-3-4',
        }}
        data-goods-id={item.product.goods_id}
        data-sku-id={item.product.goods_sn}
        data-spu-id={item.product.productRelationID}
        data-us-price={item.product.feSalePrice.usdAmount}
        data-us-origin-price={item.product.feOriginalPrice.usdAmount}
        data-attr-stock={item.attr && item.attr.stock}
        data-mall-code={item.mall_code}
        data-insurable={item.product.insurable}
        data-expose-id={`j-expose-item-${item.id}`}
        data-unuse-coupon={this.isShowNoCouponUse(item)}
        tabindex="0"
        aria-label={item.product.goods_name}
        da-event-expose={batchActive ? '' : '1-8-3-4'}
        da-tsp-id={blackFridayLabel && blackFridayLabel.tagId}
        data-behavior-label={this.getBehaviorText(item)}
        data-goods-name-before-label-id={goodsNameBeforeLabel.labelId}
        data-scene={this.getScene()}
        data-shien-club-gift-tag={showSheinClubGiftTag}
        data-other-mark={this.getOtherMark(item)}
        show-evolushein={this.showEvoluShein}
        data-discount={this.getTrackingDiscountData}
      >
        <swiper-container
          ref="swiperContainer"
          class="cart-swiper"
          init="false"
          initial-slide={0}
          slides-per-view="auto"
          direction="horizontal"
          long-swipes-ratio={0.1}
          resistance-ratio={0}
          dir={locals.GB_cssRight ? 'rtl' : 'ltr'}
          destroy-on-disconnected="false"
        >
          <swiper-slide class="cart-swiper-slide">
            <li
              class={[
                'mshe-item',
                'mshe-item-wrap',
                'ar-il',
              ]}
              style={[
                memberLimit.goods.indexOf(parseInt(item.product.goods_id)) > -1 ? { 'background-color': 'rgba(230,69,69,.1)' } : {}
              ]}
            >
              { renderCheckbox() }
              <div class={['cart-item-content', item.isNewcomerItem && !isSoldOut && 'cart-item-content__mask']}>
                <div class="m-cart__list-inner">
                  <div
                    class={[
                      'mcart-left',
                      giftItemDisable && 'cart-item__disable',
                      clubGiftItemDisable && 'cart-item__clubgift-disable'
                    ]}
                    vOn:click={this.jumpDetail}
                  >
                    <CartItemImgInfo
                      item={item}
                      index={0}
                      is-first-page={this.isFirstPage}
                      language={language}
                      soldout-ids={soldoutIds}
                      black-friday-img-goods={this.blackFridayImgGoods}
                      locals={this.locals}
                      under-price-abt-value={this.underPriceAbtValue}
                      base-cut-img={this.baseCutImg}
                      cart-discount-logo-a={this.cartDiscountLogoA}
                      is-new-cart-discount-style={this.isNewCartDiscountStyle}
                    />
                  </div>
                  <div
                    class={[
                      'mcart-right',
                      { 'sold-out-right': isSoldOut }
                    ]}
                  >
                    {renderCartRight()}
                  </div>
                </div>
              </div>
            </li>
          </swiper-slide>
          <swiper-slide
            class={[
              'operation-tab',
              'j-operation-tab',
              'cart-swiper-slide',
            ]}
          >
            { renderSimilar() }
            { renderShare() }
            { renderAddWish() }
            { renderDelele() }
          </swiper-slide>
        </swiper-container>
        { renderCustomEntry() }
      </div>
    )
  }
}

</script>

<style lang="less">
/* 设置 Swiper 容器样式 */
.cart-swiper {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
}
/* 设置滑块 slide 样式 */
.cart-swiper-slide {
  flex-shrink: 0;
}

.cart-list-item{
  background: #FFFFFF;
  .cart-item-content{
    position: relative;
    overflow-y: hidden;
    &__mask::before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(#fff, .55);
      z-index: 1;
      pointer-events: none;
    }
  }
}
li.mshe-item {
  background: #fff;
  padding: 20/75rem;
  display: flex;
  position: relative;
}
li.opt-bg{
  background-image: url(data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAcHBwcIBwgJCQgMDAsMDBEQDg4QERoSFBIUEhonGB0YGB0YJyMqIiAiKiM+MSsrMT5IPDk8SFdOTldtaG2Pj8ABBwcHBwgHCAkJCAwMCwwMERAODhARGhIUEhQSGicYHRgYHRgnIyoiICIqIz4xKysxPkg8OTxIV05OV21obY+PwP/CABEIARICwAMBIgACEQEDEQH/xAAVAAEBAAAAAAAAAAAAAAAAAAAACP/aAAgBAQAAAACkQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAH//xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oACAECEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAP/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAIAQMQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA/8QAFBABAAAAAAAAAAAAAAAAAAAAsP/aAAgBAQABPwBAj//EABQRAQAAAAAAAAAAAAAAAAAAAJD/2gAIAQIBAT8ARj//xAAUEQEAAAAAAAAAAAAAAAAAAACQ/9oACAEDAQE/AEY//9k=)
}
li.ar-il{
  width: 101%!important; /* stylelint-disable-line declaration-no-important */
}

.m-cart__list-inner {
  padding-top: 0;
  .flexbox();
  // width: 9.2rem;
  .mcart-right {
    min-width: 0;
    position: relative;
    width: 6.08rem;
  }
  .sold-out-right{
    width: 518/75rem;
    .new-right-price, .right-struct>span {
      opacity: .4;
    }
  }

  .mcart-left {
    margin-right: 20/75rem;
    position: relative;
  }

  .right-struct {
    line-height: 1.1;
    color: @sui_color_gray_dark1;
    .font-dpr(24px);
    // .line-camp(1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12/75rem;
    position: relative;
    .goods-name__before-label {
      margin-right: 4/37.5rem;
      width: 48/37.5rem;
      height: 12/37.5rem;
      object-fit: contain;
    }
    .goods-name{
      flex: 1;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 40/75rem;
    }
    .cart-save {
      position: absolute;
      right: 0;
      color: #222;
      .font-dpr(36px);
      display: inline-block;
      width: 18px;
      height: 18px;
    }
  }
  .reduce-price-tip{
    font-size: 10px;
    line-height: 24/75rem;
    color: @sui_color_highlight;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-bottom: 4/75rem;
  }

  .limit-quantity-tip{
    color: @sui_color_highlight;
    font-size: 12px;
    line-height: 28/75rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-bottom: 4/75rem;
    &.few-inventory{
      color: @sui_color_unusual;
    }
  }
  .newcomer-tip {
    position: relative;
    z-index: 2;
    padding-bottom: 4/75rem;
    line-height: 28/75rem;
    font-size: 12px;
    color: @sui_color_highlight;
  }
  .cart-item__disable {
    opacity: 0.3;
    pointer-events: none;
    &.cart-item__clubgift-disable {
      pointer-events: auto;
    }
  }
}
.operation-tab{
  display: flex;
  margin-left: 1px; // 解决 ssr 首屏红线问题
  width: auto;
  height: auto;
  .opertation-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 16/75rem 8/75rem;
    font-size: 12px;
    font-weight: 700;
    width: 120/75rem;
    &.delete {
      color: @sui_color_white;
      background: @sui_color_unusual;
    }
    &.share {
      color: @sui_color_white;
      background: #FA6338;
    }
    &.similar {
      color: @sui_color_white;
      background: #FFCE00;
    }
    &.wish {
      color: @sui_color_white;
      background: #FA6338;
    }
    .opertation-text {
      max-height: 100%;
      overflow: hidden;
      word-break: break-word;
      text-align: center;
    }
  }
}

/* Stepper 步进器
----------------------------------------------------------------*/
.cart-item__operation{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 40/75rem;
  line-height: 1;
}

.cart-item__deleteBtn {
  &[class*="iconfont"] {
    .font-dpr(36px);
    &.unvisible{
      visibility: hidden;
    }
  }
}

</style>
