<template>
  <div
    v-enterkey
    class="cart-checkout__price-wrapper isRight"
    tabindex="0"
    role="button"
    @click="handleClick"
  >
    <div class="j-totalAmount cart-checkout__price">
      <div
        v-if="showTotalOriginalPriceEntry"
        class="cart-checkout__price-del"
      >
        <span class="tips">
          <template v-if="isSuggested === 'special'">{{ language.SHEIN_KEY_PWA_30769 }} </template>
          <template v-if="isSuggested === 'special_de'">{{ language.SHEIN_KEY_PWA_31353 }} </template>
          <AmountRoll
            class="amount"
            :amount="totalOriginalPrice"
            tag="del"
          />
        </span>
      </div>
      <div
        v-if="cartInfo && cartInfo.totalPrice"
        :class="{ discount: discount }"        
        class="cart-checkout__price-total"
      >
        <AmountRoll
          class="amount"
          :amount="totalSalePrice"
        />
        <i
          v-if="showTotalOriginalPriceEntryArrow || showPrimeVipOverQuota"
          class="cart-checkout__arrow icon-forward-down32px"
          :class="[oldIconPrefix, isOpened && 'active']"
        >
        </i>
      </div>
    </div>
    <div
      v-show="(localLang == 'au' || localLang == 'nz') && isShowGST"
      class="price-gst-new"
    >
      (GST)
    </div>
    <div 
      v-show="TaxIncludeInfo && isShowTax"
      class="cart-checkout__tax-info"
    >
      {{ TaxIncludeInfo }}
    </div>

    <div
      v-show="showTotalFinallyPriceEntry || showTotalDiscountEntry"
      v-expose="{id: '1-8-2-1'}"
      class="j-totalDiscountAmount cart-checkout__total-discount"
    >
      <span class="cart-checkout__price-discount">
        <span class="total-discount-txt">{{ totalDiscountText }}</span>
        <AmountRoll
          class="amount"
          :amount="totalFinallyPrice"
        />
      </span>
      <i
        class="cart-checkout__arrow icon-forward-down32px"
        :class="[oldIconPrefix, isOpened && 'active']"
      >
      </i>
    </div>
  </div>
</template>

<script>
import { template } from '@shein/common-function'
import { mapState, mapGetters } from 'vuex'
import AmountRoll from './AmountRoll.vue'

/**
 * @description 购物车/结算BAR/价格小组件
 */
export default {
  name: 'CartCheckoutPrice',

  components: {
    AmountRoll,
  },

  props: {
    isShowGST: {
      type: Boolean,
      default: true
    },
    isShowTax: {
      type: Boolean,
      default: true
    },
    isOpened: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    ...mapGetters('cart', ['carts', 'oldIconPrefix', 'isSuggested', 'showTotalFinallyPriceEntry', 'showTotalOriginalPriceEntry', 'showTotalDiscountEntry', 'showPrimeVipOverQuota', 'showTotalOriginalPriceEntryArrow']),
    ...mapState('cart', ['language', 'cartInfo', 'cartAbtInfo', 'taxInclusiveTip', 'locals',]),
    localLang () {
      return this.locals.lang
    },
    TaxIncludeInfo() {
      if((this.cartAbtInfo?.TaxSwitch?.param?.TaxSwitchValue || '')?.split(',')?.includes('cart') && this.totalPrice?.amount > 0) return this.taxInclusiveTip || ''
      return ''
    },
    // 购物总价
    totalSalePrice() {
      if (this.isSuggested) {
        return this.cartInfo.total_price_before_coupon
      } else {
        return this.cartInfo.totalPrice
      }
    },
    // 购物车划线价
    totalOriginalPrice() {
      if (this.isSuggested === 'special_de') {
        return this.cartInfo?.suggested_sale_price ?? {}
      }
      return this.cartInfo?.crossed_price ?? {}
    },
    // 购物车到手价
    totalFinallyPrice() {
      if (this.isSuggested) {
        return this.cartInfo?.totalPrice ?? {}
      } else {
        return this.cartInfo?.total_discount_price ?? {}
      }
    },
    totalDiscountText() {
      if (!this.isSuggested) {
        return this.template('', this.language.SHEIN_KEY_PWA_23921)
      } else {
        return this.template('', this.language.SHEIN_KEY_PWA_30773)
      }
    },
    discount() {
      if (this.isSuggested) {
        if (this.isSuggested === 'special_de') {
          return this.showTotalOriginalPriceEntry
        }
        return this.showTotalFinallyPriceEntry || this.showTotalOriginalPriceEntry
      }
      return +this.cartInfo?.totalPrice?.amount < +this.cartInfo?.originPrice?.amount
    },
  },
  methods: {
    template: template,
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="less">
.cart-checkout {
  &__price-wrapper {
    flex: 1;
    line-height: 1.5;
    overflow: hidden;
    max-width: 100%;

    .price-gst-new {
      font-size: 12px;
      line-height: 1;
      color: #222222;
    }

    &.isRight {
      text-align: right;
      .cart-checkout__price-del {
        direction: rtl;
      }
      .tips {
        direction: ltr;
        unicode-bidi: bidi-override;
      }
      .cart-checkout__price {
        justify-content: flex-end;
      }
      .cart-checkout__total-discount {
        justify-content: flex-end;
      }
      .total-discount-txt {
        direction: rtl;
      }
    }
  }

  &__price {
    display: flex;
    align-items: baseline;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
  }

  &__price-del {
    flex: 1;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    margin-right: 4/75rem;
    font-size: 10px;
    color: @sui_color_gray_dark3;
  }

  &__total-discount {
    color: @sui_color_discount_dark;
    font-size: 12px;
    padding-top: 4/75rem;
    line-height: 1;
    display: flex;
    .total-discount-txt {
      white-space: normal;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      max-width: 100%;
      word-break: break-all;
      flex:1;
      padding-right: 4/75rem;
    }
  }

  &__price-discount {
    display: flex;
    white-space: nowrap;
  }

  &__price-total {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 1;

    .price-currency + .price-amount-decimal {
      text-align: left;
    }
    .amount {
      display: inline-flex;
      align-items: baseline;
      font-weight: bold;
      .price-amount {
        font-size: 17px;
      }
      .price-currency,
      .price-decimal {
        font-size: 10px;
      }
      .price-amount-decimal {
        white-space: normal;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        max-width: 100%;
        word-break: break-all;
      }
    }
    &.discount {
      color: @sui_color_discount;
    }
  }

  &__arrow {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    transform: rotate(0deg);
    transition: transform 0.2s linear;
    width: 12px;
    height: 12px;
    &.active {
      transform: rotate(180deg);
    }
    &.arrow-color{
      color: @sui_color_discount;
    }
  }
}
</style>
