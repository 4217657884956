export const EMPTY_CART_PAGE_FROM = 'empty_cart_button'

export const ADD_ON_ITEM_SA_INFO_MAP = {
  'freeShipping': {
    activity_from: 'shipping_add',
    state: 'shipping_add',
  },
  'returnCoupon': {
    activity_from: 'promotion_add',
    state: 'promotion_add',
  },
}

export const USE_BFF_API_KEYS = [
  'cart_v1',
  'cart_v2',
  'cart_v3',
]
