function bindHashChangeListener() {
  const handleHashChange = () => {
    const hashValue = window.location.hash
    const [pageStatus = '', typeId = '', promotionIdOrTplsType = ''] = hashValue.replace('#', '').split('~')
    if (pageStatus) {
      if(this.hasFetchCart){
        this.changePageStatus({ status: pageStatus, typeId, promotionIdOrTplsType })
      } else {
        let awaitFetchCart = setInterval(()=>{
          if(this.hasFetchCart){
            this.changePageStatus({ status: pageStatus, typeId, promotionIdOrTplsType })
            window.clearInterval(awaitFetchCart)
          }
        }, 500)
      }
    } else {
      this.resetPageStatus()
    }
  }

  $(window).on('hashchange', () => {
    handleHashChange()
  })

  handleHashChange()
}

export {
  bindHashChangeListener
}
