<template>
  <div v-show="false">
    <slot></slot>
  </div>
</template>
<script>
export default {
  mounted() {
    // 在mounted钩子中处理内容的初始传送可能不是一个好主意，因为我们想手动控制它。
    // 所以这里我们不实现自动传送。
  },
  methods: {
    teleport(target) {
      const targetNode = document.querySelector(target)
      if (targetNode && this.$slots.default) {
        const content = this.$slots.default[0].elm
        targetNode.appendChild(content)
      } else {
        console.warn('CustomTeleport: target not found or no content to teleport')
      }
    }
  }
}
</script>

