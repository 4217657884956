<template>
  <div
    v-if="render"
    ref="target"
    v-expose="{
      id: '1-8-3-47',
      data: {
        sku_code: item.id,
        is_checked: item.is_checked,
        is_change: isChange,
        scene: showIncentiveProductListDialog ? 'benefit_pop' : 'cart'
      },
    }"
    class="estimated-tag j-estimated-price"
    :class="[
      primeClassName,
    ]"
    :data-id="item.id"
    @click="onClick"
  >
    <span class="estimated">
      {{ estimatedText }}
    </span>
    <span
      v-if="showDiscount"
      class="discount"
    >
      {{ discountText }}
    </span>
    <template v-if="isShowCartrowEstimatedCountdown && canShowCountdown">
      <i class="line">|</i>
      <count-down-time
        font-size="10px"
        not-hide
        not-reload
        :time-stamp="timeStamp"
      />
    </template>
    <template v-if="estimatedDetailSwitch">
      <Icon
        name="sui_icon_more_right_16px"
        size="14px"
        :color="isPremiumMember ? primeColor : '#FA6338'"
        class="estimated-tag__more-right"
      />
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { Icon } from '@shein-aidc/icon-vue2'
import { daEventCenter } from '@/public/src/services/eventCenter/index'
import { getTimeStamp, canShowCountdown } from '@/public/src/pages/cartNew/utils/estimated.js'

export default {
  name: 'EstimatedTag',

  components: {
    Icon,
  },

  props: {
    item: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
    showDiscount: {
      type: Boolean,
      default: false,
    },
    discountText: {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      render: true,
      isChange: '-',
    }
  },

  computed: {
    ...mapState('cart', [
      'locals',
      'cartAbtInfo',
      'couponInfo',
      'integrityPromotionInfo',
      'batchActive',
      'filterType',
      'showIncentiveProductListDialog',
    ]),
    ...mapGetters('cart', [
      'isShowCartrowEstimatedCountdown',
      'cartCouponInfos',
      'selectPrice',
      'cartDiscountLogoA',
      'isSuggested',
    ]),
    estimatedDetailSwitch() {
      return !this.batchActive
    },
    canShowCountdown() {
      return canShowCountdown(this.item, this.cartCouponInfos, this.integrityPromotionInfo)
    },
    timeStamp() {
      return getTimeStamp(this.item, this.cartCouponInfos, this.integrityPromotionInfo)
    },
    primeColor() {
      return this.cartDiscountLogoA ? '#873C00' : '#C96E3F'
    },
    isPremiumMember() {
      return this.item.promotionTypeIds.includes(29) ? true : false
    },
    primeClassName() {
      if(this.isPremiumMember) {
        if(this.cartDiscountLogoA) return 'prime-style-a'
        return 'prime-style-b'
      }
      return ''
    },
    estimatedText() {
      if (!this.isSuggested) {
        return this.language.SHEIN_KEY_PWA_29389
      } else {
        return `${this.item.product.feFinallyPrice.amountWithSymbol} ${this.language.SHEIN_KEY_PWA_30773}`
      }
    },
    isCheckedFeFinallyPrice() {
      return {
        is_checked: this.item.is_checked,
        feFinallyPrice: this.item.product.feFinallyPrice,
      }
    },
  },

  watch: {
    isCheckedFeFinallyPrice(v, o) {
      // 如果勾选状态没变，不需要判断金额变化
      if (v.is_checked === o.is_checked) return
      // 如果上一次未显示到手价标签，则无需手动上报
      if (!o.feFinallyPrice) return

      this.isChange = this.getIsChangeByUnitPrice(o.feFinallyPrice?.amount, v.feFinallyPrice?.amount)
      this.onExpose()
    },
  },

  methods: {
    ...mapMutations('cart', ['changeEstimatedPriceDrawerParams']),
    onClick() {
      if (!this.estimatedDetailSwitch) return
      this.changeEstimatedPriceDrawerParams({
        show: true,
        cartItem: this.item,
      })
    },
    getIsChangeByUnitPrice(beforePrice, price) {
      if (!beforePrice || !price) return '-'
      if (+beforePrice === +price) return 'remain'
      if (+beforePrice - +price > 0) return 'smaller'
      return 'increase'
    },
    onExpose() {
      this.$nextTick(() => {
        if (this.getInViewport()) {
          // 在视图中，手动曝光一次
          daEventCenter.triggerNotice({
            daId: '1-8-3-47',
            bindData: [
              {
                sku_code: this.item.id,
                is_checked: this.item.is_checked,
                is_change: this.isChange,
                scene: this.showIncentiveProductListDialog ? 'benefit_pop' : 'cart'
              },
            ],
          })
        } else {
          // 可能已经曝光过，所以需要重新渲染再次订阅曝光事件
          this.onRender()
        }
      })
    },
    getInViewport() {
      const targetRect = this.$refs.target.getBoundingClientRect()
      const viewportHeight = window.innerHeight
      return targetRect.top < viewportHeight && targetRect.bottom > 0
    },
    onRender() {
      this.render = false
      this.$nextTick(() => {
        this.render = true
      })
    },
  },
}
</script>
  
<style lang="less" scoped>
.estimated-tag {
  display: inline-flex;
  max-width: 100%;
  align-items: center;
  height: 18/37.5rem;
  padding: 2/37.5rem 4/37.5rem;
  margin-top: 4/37.5rem;
  color: #FA6338;
  line-height: normal;
  background: linear-gradient(90deg, #FFECE9 2.73%, #FFFAF9 100%), linear-gradient(90deg, #FDF4D0 0.02%, rgba(253, 244, 208, 0.30) 100.8%);
  .estimated,
  .discount {
    font-size: 10/37.5rem;
    font-style: normal;
    color: #FA6338;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .estimated {
    font-weight: 400;
  }
  .discount {
    margin-left: 4/75rem;
    font-weight: 500;
  }
  .line {
    flex-shrink: 0;
    display: inline-block;
    width: 4/37.5rem;
    color: #FDB9A6;
    text-align: center;
    font-family: "SF UI Text";
    font-size: 10/37.5rem;
    font-style: normal;
    font-weight: 400;
    margin: 0 4/37.5rem;
  }
  .new-down-time {
    flex-shrink: 0;
    line-height: 0;
    /deep/.count-number {
      height: 24/75rem;
      line-height: 24/75rem;
      padding: 0 4/75rem;
    }
  }
  &__more-right {
    flex-shrink: 0;
    margin-left: 2/75rem;
    transform: rotateY(0deg)/* rtl: rotateY(180deg) */;
  }
}
.estimated-tag.prime-style-a {
  color: #873C00;
  .estimated,
  .discount {
    color: #873C00;
  }
  .line {
    color: #873C00;
    opacity: 0.4;
  }
}
.estimated-tag.prime-style-b {
  color: #C96E3F;
  .estimated,
  .discount {
    color: #C96E3F;
  }
  .line {
    color: #C96E3F;
    opacity: 0.4;
  }
}
</style>
