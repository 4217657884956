import { getGoodsUrl } from 'public/src/pages/goods_detail/utils/common'
import ClientOnly from 'vue-client-only'
import { mapState } from 'vuex'
import { isIPhoneX, template, transformImg as _transformImg, htmlDecode as _htmlDecode } from '@shein/common-function'

export default {
  data: function () {
    return {
      isIPhoneX: false
    }
  },
  components: {
    ClientOnly
  },
  computed: {
    ...mapState('cart', ['locals']),
    WEB_CLIENT(){
      return this.locals.WEB_CLIENT
    },
    IS_RW(){
      return this.locals?.IS_RW
    },
    goods_img_placeholder(){
      return this.locals.LAZY_IMG
    },
    localLang(){
      return this.locals.lang
    },
    SiteUID(){
      return this.locals.SiteUID
    },
    CART_STOCK_TIPS_SWITCH(){
      return this.locals.CART_STOCK_TIPS_SWITCH
    },
    local_GB_cssRight () {
      return this.locals.GB_cssRight
    },
    isServer(){
      return typeof window == 'undefined'
    },
    IMG_LINK () {
      return this.locals.IMG_LINK || {}
    },
  },
  mounted() {
    this.isIPhoneX = isIPhoneX()
  },
  methods: {
    getGoodsUrl: getGoodsUrl,
    transformImg: function (img) {
      return this.isServer && img ? img.replace(/\b(\.jpg|\.png)\b/, '.webp') : _transformImg({ img: img })
    },
    template: template,
    htmlDecode: function (msg){
      return typeof window != 'undefined' ? _htmlDecode({ text: msg }) : msg
    },
    pageOnload (saPageInfo) {
      if (!saPageInfo || !appEventCenter) return
      window.SaPageInfo = saPageInfo
      appEventCenter.$emit('pageOnload')
    }
  }
}
