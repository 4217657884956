<script setup>
import { ref, onMounted, computed } from 'vue'
import {
  Dialog as SDialog,
  Button as SButton,
} from '@shein/sui-mobile'
import DiscountsAmount from './DiscountsAmount.vue'
import DiscountsList from './DiscountsList.vue'
import useDeleteTips from '../../hooks/useDeleteTips'
import { DELETE_TIPS_TYPES } from '../../constant'

const {
  isShowDeleteTips,
  deleteTipsType,
  deleteTipsTitle,
  deleteTipsText,
  confirmBtnText,
  deleteBtnText,
  onClose,
  onClickConfirm,
  onClickDelete,
} = useDeleteTips()

const bgImg = ref('')
onMounted(() => {
  const { PUBLIC_CDN = '' } = gbCommonInfo || {}
  bgImg.value = `url("${PUBLIC_CDN}/pwa_dist/images/cart/delete_tips_bg-fda7c5f2b2.png")`
})

// 是否展示未来可能的折扣
const isShowDiscountsList = computed(() => {
  return [
    DELETE_TIPS_TYPES.PROMTOTECUT_CHECKED_NO_DISCOUNT,
    DELETE_TIPS_TYPES.PROMTOTECUT_UNCHECKED,
    DELETE_TIPS_TYPES.BATCH_NORMAL,
    DELETE_TIPS_TYPES.BATCH_SIMILAR,
  ].includes(deleteTipsType.value)
})
// 是否展示折扣信息
const isShowDiscountsAmount = computed(() => DELETE_TIPS_TYPES.PROMTOTECUT_CHECKED_HAS_DISCOUNT === deleteTipsType.value)
</script>

<template>
  <SDialog
    :visible.sync="isShowDeleteTips"
    show-close
    :close-on-click-modal="false"
    close-color="#fff"
    class="discounts-tips"
    @close-from-icon="onClose"
  > 
    <template #title>
      <header
        class="discounts-tips__title"
        :class="{'discounts-tips__title_size-small': isShowDiscountsList}"
      >
        {{ deleteTipsTitle }}
      </header>
    </template>
    <DiscountsAmount v-show="isShowDiscountsAmount" />
    <DiscountsList v-show="isShowDiscountsList" />
    <p
      v-show="isShowDiscountsAmount"
      class="discounts-tips__text"
    >
      {{ deleteTipsText }}
    </p>
    <template #footer>
      <SButton
        :type="['primary', 'H72PX']"
        width="100%"
        @click="onClickConfirm"
      >
        {{ confirmBtnText }}
      </SButton>
      <div
        class="discounts-tips__delete-btn"
        @click="onClickDelete"
      >
        {{ deleteBtnText }}
      </div>
    </template>
  </SDialog>
</template>

<style lang="less" scoped>
.discounts-tips {
  /deep/ .S-dialog__wrapper {
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 99/37.5rem;
      content: "";
      background: v-bind(bgImg) no-repeat;
      background-size: contain;
      z-index: @zindex-hack-negative;
    }
  }
  &__title {
    color: @sui_color_discount;
    font-size: 16/37.5rem;
    font-weight: bold;
    &_size-small {
      font-size: 14/37.5rem;
      color: @sui_color_discount_dark;
    }
  }
  &__text {
    margin-top: 12/37.5rem;
    color: @sui_color_gray_dark1;
    font-size: 14/37.5rem;
    text-align: center;
  }
  &__delete-btn {
    margin-top: 12/37.5rem;
    color: @sui_color_link;
    font-size: 14/37.5rem;
  }
}
</style>
