<script setup>
import useDeleteTips from '../../hooks/useDeleteTips'
import { Icon } from '@shein-aidc/icon-vue2'

const { discountInfoList } = useDeleteTips()
</script>

<template>
  <ul class="discounts-list">
    <li
      v-for="(item, i) in discountInfoList"
      :key="i"
      class="discounts-item"
    >
      <Icon
        :name="item.iconName"
        size="24px"
        color="#C44A01"
      />
      <span class="discounts-item__name">{{ item.name }}</span>
    </li>
  </ul>
</template>

<style lang="less" scoped>
.discounts-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 8/37.5rem 8/37.5rem 4/37.5rem;
  left: -0.43rem;
  width: 7.6rem;
  height: 58/37.5rem;
  .discounts-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 84/37.5rem;
    height: 100%;
    &:not(:last-child) {
      &::after {
        content: '';
        position: absolute;
        right: -4/37.5rem;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 21.5/37.5rem;
        background-color: @sui_color_discount_dark;
      }
    }
    &__name {
      .text-overflow();
      max-width: 100%;
      font-size: 12/37.5rem;
      color: @sui_color_discount_dark;
    }
  }
}
</style>
