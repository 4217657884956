<template>
  <div class="middle-wrap-box">
    <div class="top">
      <div class="left">
        <slot name="left"></slot>
      </div>
      <div class="right">
        <slot name="right"></slot>
      </div>
    </div>
    <div class="bottom">
      <slot name="bottom"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MiddleWrapBox',
}
</script>

<style lang="less" scoped>
.middle-wrap-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .left {
    display: inline-flex;
    align-items: center;
    flex: 1;
    overflow: hidden;
    margin-right: 12/75rem;
    flex-wrap: wrap; // 折扣标签展示不下时向下换行
    &.is-wrap {
      flex-wrap: wrap-reverse;
    }
  }
  .right {
    flex-shrink: 0;
  }
  .bottom {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
