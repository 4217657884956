<template>
  <SPopover
    v-model="showPop"
    :propStyle="propStyle"
    :placemen="placemen"
    trigger-type="user"
    show-close-icon
  >
    <span :style="tipsStyles">{{ content }}</span>
    <sui_icon_info_12px_2
      slot="reference"
      class="j-cart-price-popover-icon"
      size="11px"
      @click.native.stop="togglePopover"
    />
  </SPopover>
</template>

<script>
import { Popover as SPopover } from '@shein/sui-mobile'
import { sui_icon_info_12px_2 } from '@shein-aidc/icon-vue2'

/**
 * @description 购物车价格提示
 */
export default {
  name: 'CartItemPricePopover',
  components: {
    SPopover,
    sui_icon_info_12px_2,
  },
  emits: ['toggle'],
  props: {
    // 气泡提示内容
    content: {
      type: String,
      default: '',
    },
    // popover提示样式
    propStyle: {
      type: Object,
      default: () => ({
        width: '6.4rem',
        zIndex: Number.MAX_SAFE_INTEGER,
      }),
    },
    // 气泡提示样式
    tipsStyles: {
      type: Object,
      default: () => ({
        'white-space': 'normal',
        'display': '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 3,
        'overflow': 'hidden',
        'max-width': '100%',
        'word-break': 'break-all',
      })
    },
    // 监听滚动元素
    listenTarget: {
      type: String,
      default: '',
    },
    // 遮挡气泡的元素
    gapTarget: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showPop: false,
      placemen: 'bottom',
    }
  },
  mounted() {
    this.addListen()
  },
  beforeDestroy() {
    this.removeScroll()
  },
  methods: {
    addListen() {
      this.$watch('showPop', (val) => {
        if (val) {
          this.listenScroll()
          this.exposeInstance()
        } else {
          this.removeScroll()
        }
      })
    },
    togglePopover() {
      if (!this.showPop) {
        this.placemen = this.getPlacemenDirection()
      }
      this.showPop = !this.showPop
      this.$emit('toggle', this.showPop)
    },
    getListenElem() {
      if (this.listenTarget) {
        return document.querySelector(this.listenTarget)
      }
      return document
    },
    getPlacemenDirection() {
      const { y: checkoutTop } = this.gapTarget ? document.querySelector(this.gapTarget)?.getBoundingClientRect() : {
        y: Number.MAX_SAFE_INTEGER
      }
      const { y: popoverIcon } = document.querySelector('.j-cart-price-popover-icon')?.getBoundingClientRect() ?? {}
      const popoverContentHeight = 60
      if (checkoutTop - popoverIcon < popoverContentHeight) {
        return 'top'
      }
      return 'bottom'
    },
    listenScroll() {
      this.getListenElem()?.addEventListener?.('scroll', this.togglePopover)
    },
    removeScroll() {
      this.getListenElem()?.removeEventListener?.('scroll', this.togglePopover)
    },
    exposeInstance() {
      window.__cart_item_price_popover__ = this
    }
  }
}
</script>
