<script setup>
import useDeleteTips from '../../hooks/useDeleteTips'

const {
  singleDiscountAmount
} = useDeleteTips()
</script>

<template>
  <div class="discounts-amount">
    <span class="discounts-amount__text">{{ singleDiscountAmount }}</span>
  </div>
</template>

<style lang="less" scoped>
.discounts-amount {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38/37.5rem;
  &__text {
    font-size: 32/37.5rem;
    font-weight: bold;
    font-family: 'SF UI Text';
    color: @sui_color_discount;
  }
}
</style>
