<script setup>
import { computed } from 'vue'
import FindSimilarTips from './components/findSimilarTips/FindSimilarTips.vue'
import DisCountsTips from './components/discountsTips/DiscountsTips.vue'
import { DELETE_TIPS_TYPES } from './constant'
import useDeleteTips from './hooks/useDeleteTips'

const {
  deleteTipsType
} = useDeleteTips()

const isShowFindSimilarTips = computed(() => DELETE_TIPS_TYPES.SIMILAR === deleteTipsType.value)
const isShowDiscountTips = computed(() => {
  return [
    DELETE_TIPS_TYPES.PROMTOTECUT_CHECKED_NO_DISCOUNT,
    DELETE_TIPS_TYPES.PROMTOTECUT_CHECKED_HAS_DISCOUNT,
    DELETE_TIPS_TYPES.PROMTOTECUT_UNCHECKED,
    DELETE_TIPS_TYPES.BATCH_NORMAL,
    DELETE_TIPS_TYPES.BATCH_SIMILAR,
  ].includes(deleteTipsType.value)
})
</script>

<template>
  <div>
    <FindSimilarTips
      v-if="isShowFindSimilarTips"
    />
    <DisCountsTips
      v-if="isShowDiscountTips"
    />
  </div>
</template>

