<script lang="jsx">
import { sui_icon_brandsale_15px } from '@shein-aidc/icon-vue2'
import { isVNode } from '@/public/src/pages/cartNew/utils'
import VipPrice from 'public/src/pages/components/product/VipPrice.vue'
// import PrimeVipIcon from 'public/src/pages/common/prime/primeVipIcon.vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { amountWithSymbolTransform } from '../../../utils/index'
import MiddleWrapBox from '../../MiddleWrapBox.vue'
import CartItemPricePopover from './CartItemPricePopover.vue'
import DiscountTag from '@/public/src/pages/cartNew/components/list/item/DiscountTag.vue'

export default {
  name: 'CartItemPrice',
  functional: true,
  components: {
    sui_icon_brandsale_15px,
    CartItemPricePopover,
    DiscountTag,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    promotionCutPriceShow: {
      type: Boolean,
      default: false
    },
    iconPrefix: {
      type: String,
      default: ''
    },
    locals: {
      type: Object,
      default() {
        return {}
      }
    },
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    cartDiscountLogoA: {
      type: Boolean,
      default: false
    },
    showBeforeCouponPrice: {
      type: Boolean,
      default: false
    },
    showDiscountTag: {
      type: Boolean,
      default: false
    },
    discountText: {
      type: String,
      default: '',
    }
  },
  render(h, { props, slots }) {
    const {
      item,
      iconPrefix,
      locals,
      promotionCutPriceShow,
      cartDiscountLogoA,
      showBeforeCouponPrice,
      language,
      showDiscountTag,
      discountText,
    } = props
    const { IS_RW, GB_cssRight } = locals
    const isSuggested = locals.IS_SUGGESTED
    const isInversion = item.is_inversion === 1
    const isPremiumMember = item.promotionTypeIds.includes(29) || item.isSheinClubGiftItem
    const isVipItem = item.promotionTypeIds?.includes(12)
    const isDiscountProduct = !isVipItem && !isPremiumMember && (promotionCutPriceShow || item.product.insurable == 1 || item.product?.feSalePrice.amount !== item.product?.feOriginalPrice.amount || (isSuggested == 'special_de' && [5].includes(item.suggestedPriceType) && item.product?.feSalePrice.amount !== item.product?.suggested_sale_discount.amount))
    const unitPriceAmountWithSymbol = showBeforeCouponPrice
      ? amountWithSymbolTransform(item.unit_price_before_coupon.amountWithSymbol, locals.currencyDecPointSymbol)
      : amountWithSymbolTransform(item.product?.feSalePrice?.amountWithSymbol, locals.currencyDecPointSymbol)

    /**
     * 判断合规站点pl\fr 商品属于倒挂 活动A类
     * 判断合规站点de 商品属于场景1、3、5才处理促销标签 pageId=1384306511
     * @param {String} promotion_type
     * @returns 
     */
    function getIsSuggestedInversionPromotionA() {
      if (isSuggested == 'special_de') {
        return ![1, 3, 5].includes(item.suggestedPriceType)
      }
      if (isSuggested && item.is_inversion == 1) {
        // 如果此时商品未参与B类活动，且未参与SHEIN CLUB，则此时展示的价格为黑色
        // 如果此时商品参与了B类活动，或参与了SHEIN CLUB，则此时可以展示B类活动和SHEIN CLUB的价格颜色、活动标签、价格前的icon，和线上保持一致
        return !(item.isPromotionB || item.isSheinClubItem)
      }
      return false
    }

    function getPromotionIcon() {
      const promotionTypeIds = item.promotionTypeIds || []
      if (
        (promotionTypeIds.includes(3) && !getIsSuggestedInversionPromotionA())
        || (promotionTypeIds.includes(31) && item?.promotionLogoTypeList?.includes(3) && !getIsSuggestedInversionPromotionA())
      ) {
        return 'sui_icon_time_15px'
      } else if (promotionTypeIds.includes(8) && !getIsSuggestedInversionPromotionA()) {
        return 'sui_icon_phone_15px'
      } else if (promotionTypeIds.includes(10) && item.flashType == 6 && !getIsSuggestedInversionPromotionA()) {
        return <sui_icon_brandsale_15px
          class="sui-icon"
          size="15px"
          color="#FFCD94"
        />
      } else if ((promotionTypeIds.includes(10) && [1, 4, 5].includes(Number(item.flashType)) && !getIsSuggestedInversionPromotionA()) || (promotionTypeIds.includes(11) && !getIsSuggestedInversionPromotionA())) {
        return 'sui_icon_flashsale_15px'
      }
      return ''
    }

    const renderPromotionIcon = () => {
      const promotionIcon = getPromotionIcon()
      if (!promotionIcon) return null
      if (isVNode(promotionIcon)) return promotionIcon
      return (
        <i
          class={[
            promotionIcon,
            promotionIcon == 'sui_icon_flashsale_15px' ? 'iconfont-yellow' : 'iconfont-red', iconPrefix
          ]}
        ></i>
      )
    }

    const renderVipIcon = () => {
      if (!isVipItem) return null
      if (IS_RW) {
        return <VipPrice class="rw-vip-price" price={item.unitPrice.amountWithSymbol} />
      } else {
        return <em
          class={[
            'vip-exclusive-tag_color-black',
            GB_cssRight ? 'vip-exclusive-tag__ar' : 'vip-exclusive-tag'
          ]}
        ></em>
      }
    }

    const renderIcon = () => {
      if (isPremiumMember) {
        return (<Icon
          class="premium-icon"
          name="sui_icon_club_logo_fill_14px" 
          size="16px" 
          color={cartDiscountLogoA ? '#873C00' : '#C96E3F'}
        />)
      } else {
        return renderPromotionIcon()
      }
    }
    
    const renderPrice = () => {
      if (isVipItem && IS_RW) return null
  
      const getPriceCls = () => {
        const res = ['cart-item-price']
        if (isSuggested && isSuggested != 'special_de' && isInversion && !item.isPromotionB && !item.isSheinClubItem) return res
        if (isSuggested == 'special_de' && ![1, 3, 5].includes(item.suggestedPriceType)) return res
        if (isDiscountProduct) { // 折扣价格类型
          res.push('discount-color')
        } else if (isVipItem) {
          res.push('vip-color')
        } else if (isPremiumMember) { // 付费会员专享类型
          res.push(cartDiscountLogoA ? 'a-premium-color' : 'premium-color')
        }
        return res
      }

      return (
        <div class={['cart-item-price__contain', !isSuggested && 'cart-price-wrap']}>
          { renderIcon() }
          { renderVipIcon() }
          <span
            class={getPriceCls()}
            domPropsInnerHTML={unitPriceAmountWithSymbol}
          >
          </span>
          { isSuggested === 'special_de' && renderOriginalDel() }
          { (!isSuggested || (isSuggested && isSuggested !== 'special_de')) && renderDelPrice() }
        </div>
      )
    }

    const renderOriginalDel = () => {
      if (isSuggested === 'special_de' && [1, 3].includes(item.suggestedPriceType)) {
        const getPriceIcon = () => {
          if (item.suggestedPriceType === 3) {
            return (
              <CartItemPricePopover
                content={language.SHEIN_KEY_PWA_31354}
                gapTarget=".j-cart-checkout"
              >
              </CartItemPricePopover>
            )
          }
        }
        const getPrice = () => {
          return (
            <del>
              { item.product?.retailPrice?.amountWithSymbol }
            </del>
          )
        }
        return (
          <span class="cart-original-del">
            { getPrice() }
            { getPriceIcon() }
          </span>
        )
      }
    }

    const renderDelPrice = () => {
      const isShowDelPrice = isSuggested === 'special_de'
        ? [1, 5].includes(item.suggestedPriceType)
        : (isVipItem || +item.product?.feSalePrice?.amount < +item.product?.feOriginalPrice?.amount || item.product.insurable == 1) && (!isSuggested || !isInversion)
      const getPriceNum = () => {
        return item.product.insurable == 1 && item.product.sale_price_before_insured
          ? `<del aria-hidden="true">${item.product.sale_price_before_insured.amountWithSymbol}</del>`
          : `<del aria-hidden="true">${isSuggested === 'special_de' ? item.product?.suggested_sale_price?.amountWithSymbol : item.product?.feOriginalPrice?.amountWithSymbol}</del>`
      }
      const getPriceText = () => {
        if (isSuggested && isSuggested === 'special') {
          return `${getPriceNum()} ${language.SHEIN_KEY_PWA_30769}`
        }
        if (isSuggested && isSuggested === 'special_de') {
          return `${getPriceNum()} ${language.SHEIN_KEY_PWA_31353}`
        }
        return getPriceNum()
      }
      return isShowDelPrice && (
        <div class="del-price" domPropsInnerHTML={getPriceText()}></div>
      )
    }

    const renderDiscountTag = () => {
      return (<DiscountTag item={item} discountText={discountText} />)
    }

    return (
      <div class={[
        'new-cart-item_price',
        { 'item-price__disable': !item.is_checked && item.isGiftItem }
      ]}>
        <div class="new-right-price">
          <MiddleWrapBox>
            <template slot="left">
              { renderPrice() }
              { showDiscountTag && renderDiscountTag() }
            </template>
            <template slot="right">
              { slots().operation }
            </template>
            <template slot="bottom">
              { isSuggested === 'special_de' && renderDelPrice() }
            </template>
          </MiddleWrapBox>
        </div>
      </div>
    )
  }
}
</script>

<style lang="less">
.new-cart-item_price {
  overflow: hidden;
  flex: 1;
  white-space: nowrap;
  &.item-price__disable {
    .del-price,
    .main-content {  
      opacity: 0.3;
      pointer-events: none
    }
    .left {
      opacity: 0.3;
      pointer-events: none
    }
    .bottom {
      opacity: 0.3;
    }
  }
  .del-price {
    .font-dpr(20px);
    color: #767676;
    display: inline;
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    del {
      text-transform: uppercase;
    }
  }
  .new-right-price {
    display: flex;
    align-items: center;
    min-height: 40/75rem;
  
    .vip-exclusive-tag_color-black {
      margin-right: 0.1067rem;
    }

    .sui-icon {
      margin-right: 4/75rem;
      vertical-align: sub!important; /* stylelint-disable-line declaration-no-important */
    }

    .iconfont-red {
      color: @sui_color_promo;
      font-size: 15px;
      margin-right: 4/75rem;
    }
  
    .iconfont-yellow {
      color: @sui_color_flash;
      font-size: 15px;
      margin-right: 4/75rem;
    }
  
    .rw-vip-price {
      margin-top: 0.0233rem;
      margin-bottom: 0.0533rem;
      margin-right: 0.1067rem;
      display: inline;
    }
  
    .cart-item-price__contain {
      display: inline-flex;
      max-width: 100%;
      align-items: baseline;
      margin-right: 4px;
    }

    .cart-price-wrap {}
    .premium-icon {
      margin-right: 2/37.5rem;
    }
  
    .premium-color {
      color: #C96E3F;
    }
    .a-premium-color {
      color: #873C00;
    }
  
    .discount-color {
      color: @sui_color_discount;
    }
    .vip-color {
      color: #A78A45;
    }
  
    .prime-vip-svg{
      margin-right: 4/75rem;
    }
  }
  .sui-icon-common__wrap {
    align-self: center;
  }
  .cart-price-wrap {
    flex-wrap: nowrap; // 视觉又变更为划线价溢出不换行了
    align-items: baseline;
    margin-right: 4/75rem;
    .del-price {
      margin-left: 0;
      display: block;
      // flex-basis: auto; // ui要求主价格溢出时划线价省略
    }
  }
  .cart-item-price {
    text-transform: uppercase;
    font-weight: bold;
    display: inline-flex;
    align-items: baseline;
    direction: ltr /* rtl: ignore */;
    height: 40/75rem;
    line-height: 40/75rem;

    .price-currency, .price-decimal {
      font-size: 11px;
    }

    .price-amount-decimal {
      white-space: normal;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      max-width: 100%;
      word-break: break-all;
    }

    .price-currency{
      flex: 1;
    }
    .price-amount {
      font-size: 17px;
    }
  }
  .car-price-wrap .del-price {
    display: block;
  }
  .cart-item-price + .del-price {
    margin-left: 4/75rem;
  }
  .cart-original-del {
    flex: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-left: 4/75rem;
    .font-dpr(20px);
    color: #767676;
    text-overflow: ellipsis;
    > del {
      flex: 1;
      min-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: inherit;
    }
    > span {
      margin-left: 4/75rem;
      flex-shrink: 0;
    }
  }
  .left {
    margin: 1px 0;
  }
  .price-tags {
    display: flex;
    width: 100%;
    .tag {
      padding: 4/75rem 8/75rem;
      margin: 4/75rem 4/75rem 0 0;
      color: @sui_color_discount_dark;
      background-color: @sui_color_promo_bg;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
  
</style>
<style lang="less">
@s3_icon: '/pwa_dist/images/sheinvip2/s3_icon-7c5e305bc1.png';
@s3_icon_ar: '/pwa_dist/images/sheinvip2/s3_icon_ar-827f0ce0d6.png';

.vip-exclusive-tag,
.vip-exclusive-tag__ar {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 3.23rem .77rem;
  width: .85rem;
  height: .27rem;
  &_color-yellow {
    background-position: -1.45rem -.25rem;
  }
  &_color-black {
    background-position: -.48rem -.25rem;
  }
}
.vip-exclusive-tag {
  background-image: url(@s3_icon);
}
.vip-exclusive-tag__ar {
  background-image: url(@s3_icon_ar);
  vertical-align: -0.026667rem;
}
</style>
