
<template>
  <div
    v-tap="analysisData('1-8-7-45')"
    v-expose="analysisData('1-8-7-46')"
    class="cart-store-header"
    @click="goToStore"
  >
    <template v-if="item.preferred_seller_store == 1">
      <ClientOnly>
        <PreferredSellerStoreIcon />
      </ClientOnly>
    </template>
    <i
      v-else
      class="sui_icon_store_15px"
      :class="[iconPrefix]"
    >
    </i>
    <span class="store-title">{{ storeTitle }}</span>
    <i
      v-if="storeTitle != 'SHEIN' && storeCode != 1"
      class="suiiconfont sui_icon_more_right_12px_2"
    ></i>
  </div>
</template>

<script>
import PreferredSellerStoreIcon from 'public/src/pages/cartNew/components/preferredSellerStoreIcon.vue'
import { mapState, mapGetters } from 'vuex'
import { markPoint } from 'public/src/services/mark/index.js'

export default {
  components: {
    PreferredSellerStoreIcon,
  },
  // 组件传参
  props: {
    item: {
      type: Object,
      default(){
        return {}
      }
    },
  },
  // 计算属性
  computed: {
    ...mapState('cart', ['storeSelectsInfo', 'locals']),
    ...mapGetters('cart', ['showOBMStore', 'showThreeStore', 'iconPrefix']),
    siteName () {
      return this.locals.IS_RW ? 'ROMWE' : 'SHEIN'
    },
    storeTitle(){
      if(this.showOBMStore) {
        return this.item.store_title
      }
      return this.showThreeStore && this.item.business_model == 1 ? this.item.store_title : this.siteName
    },
    storeCode(){
      return this.item.store_code
    },
  },

  // 方法实现
  methods: {
    goToStore: function (){
      if(this.storeTitle == this.siteName || this.storeCode == 1){
        return
      }
      if(this.storeSelectsInfo[this.storeCode]){
        markPoint('toNextPageClick', 'public')
        this.$router.push(gbCommonInfo.langPath + this.storeSelectsInfo[this.storeCode])
      }
    },
    analysisData(id){
      return {
        id: id,
        once: false,
        data: {
          store_code: this.storeCode,
          store_tp: this.item.store_type == 1 ? 1 : 2,
          preferred_seller: this.item.preferred_seller_store == 1 ? 1 : 0,
        }
      }
    }
  },
}
</script>

<style lang='less' scoped>
.cart-store-header{
    display: flex;
    align-items: center;
    line-height: 1;
    padding-bottom: 12/75rem;
    .store-title{
      color: #222222;
      font-size: 11px;
      padding: 0 4/75rem 0 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
    }
    i{
      width: 12px;
      height: 12px;
      font-size: 12px;
      color: #222;
      &.sui_icon_store_15px{
        font-size: 12px;
        margin-right: 4/75rem;
      }
    }
}
</style>
