<template>
  <div
    ref="observeMeRef"
    v-expose="{
      id: '1-8-3-59',
      data: {
        actual_point: content.type,
        available_point: getAnalysisAvailablePoint(),
        information: getAnalysisInformation(),
        ...getExtraAnalysisData(content),
      }
    }"
    v-tap="{
      id: '1-8-3-60',
      data: {
        actual_point: content.type,
        information: getAnalysisInformation(),
        ...getExtraAnalysisData(content),
      }
    }"
    class="incentive-popover-content"
    @click="handleClick"
  >
    <div
      v-if="content.type != 'coupon'"
      class="popover-left img-block"
    >
      <div
        v-for="(imgItem, index) in imgs"
        :key="index"
        class="incentive-img-container"
      >
        <img
          class="popover-img lazyload"
          :src="locals.LAZY_IMG"
          :data-src="imgItem.img_url"
          :alt="imgItem.goods_name"
          aria-hidden
        />
        <div
          v-if="imgItem.discount"
          class="img-discount"
        >
          {{ imgItem.discount }}
        </div>
        <div
          v-if="imgs.length > 1 && index === imgs.length - 1 && moreNums"
          class="more-nums-mask"
        >
          <span>{{ moreNums }}</span>
        </div>
      </div>
    </div>
    <div
      v-else
      :class="[
        'popover-left',
        'coupon-block',
        content.couponType 
      ]"
    >
      <template v-if="content.couponType == 'free_shipping'">
        <div class="free-shipping-text">
          {{ language[content.couponDownText] }}
        </div>
      </template>
      <template v-else>
        <!-- <div class="coupon-up">
          {{ content.couponUpText }}
        </div> -->
        <ShrinkText
          class="coupon-up"
          :content="content.couponUpText"
          :max-width="54"
          :options="{
            fontSize: 14,
            step: 1,
            minFontSize: 10
          }"
        />
        <div class="coupon-down">
          {{ language[content.couponDownText] }}
        </div>
      </template>
    </div>
    <div class="tip-content">
      <Icon
        v-if="content.iconName"
        :name="content.iconName"
        size="14px"
        :color="content.color"
        is-responsive-name
        class="content-left"
      />
      <span class="content-text">
        <span v-html="content.bubbleTips"></span>
        <Icon
          v-if="content.type != 'coupon'"
          name="sui_icon_more_right_16px_2"
          size="10px"
        />
      </span>
      <span class="content-right">
        <CountDownTime
          v-if="content.countDown >= 0"
          :time-stamp="Number(content.countDown)"
          not-reload
          not-hide
          class="incentive-countdown"
          font-size="10px"
        />
      </span>
    </div>
  </div>
</template>

<script setup>
import { defineExpose, computed, onMounted, ref, onBeforeUnmount } from 'vue'
import { getMainImageUrl, useStore, scrollToCartItem } from 'public/src/pages/cartNew/utils/index.js'
import { Icon } from '@shein-aidc/icon-vue2'
import { INCENTIVE_TYPES, POPOVER_CONFIGS } from './incentiveTools.js'
import { isFreeShippingCoupon } from 'public/src/pages/components/coupon/mall/utils'
import ShrinkText from 'public/src/pages/common/addOnItem/layout/commonComponents/ShrinkText.vue'


// ['1002673461833781290', '1002673461833781289']
// {
//   type: 'flash_sale',
//   iconName: 'sui_icon_lowest_price_12px_2',
//   color: 'rgba(250, 99, 56, 1)',
//   bubbleTips: 'false sale end in 1 day 2 hours 3 minutes 4 seconds',
//   countDown: 1717137951,
//   couponUpText: 'Free',
//   couponDownText: 'Shipping',
//   couponType: 'free_shipping' // free_shipping, discount_shipping, discount_coupon,
// }
const rootStore = useStore()
const state = computed(() => {
  return rootStore?.state?.cart || {}
})
const content = computed(() => {
  return state.value.incentivePopoverContent
})
const itemIds = computed(() => {
  return state.value.incentiveItemIds || []
})
const locals = computed(() => {
  return state.value.locals || {}
})
const language = computed(() => {
  return state.value.language || {}
})

const imgs = computed(() => {
  const cartItemMap = state.value.cartItemMap
  return getShowImgs(itemIds.value, locals.value, cartItemMap)
})
const moreNums = computed(() => {
  let num = itemIds.value.length - 2
  if(num > 99) return '+99'
  if(num > 0) return '+' + num
  return ''
})
const observeMeRef = ref(null)
let observer = null
onMounted(() => {
  if (!observeMeRef.value) return

  const options = {
    root: null, // 视口作为根
    rootMargin: '0px',
    threshold: 0.1 // 10%
  }
  const popoverKeepTime = rootStore.getters['cart/incentiveTimeConfig']?.popoverKeepTime || 3000 // popover显示时间
  observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        if(state.value.incentivePopoverKeepTimer) {
          clearTimeout(state.value.incentivePopoverKeepTimer)
          state.value.incentivePopoverKeepTimer = null
        }
        getIncentiveData()
        state.value.showIncentiveTimes++
        rootStore.dispatch('cart/updateInducementSortTypes')
        state.value.incentivePopoverKeepTimer = setTimeout(() => {
          closeIncentivePopover()
        }, popoverKeepTime)
      }
    })
  }, options)

  observer.observe(observeMeRef.value)
})

onBeforeUnmount(() => {
  if (observer) {
    observer.disconnect()
  }
})

function getShowImgs(items, locals, cartItemMap, limit = 2) {
  let imgs = []
  if(!items || !items.length) return imgs
  for(let i = 0; i < limit; i++) {
    const item = cartItemMap[items[i]]
    if(item) {
      imgs.push({
        goods_name: item.product?.goods_name,
        goods_id: item.product?.goods_id,
        img_url: getMainImageUrl(item, locals.IS_RW),
        discount: getImgDiscount(item, locals.lang)
      })
    }
  }
  return imgs
}

function getImgDiscount(item, localLang) {
  const unit_discount = item.product?.unit_discount || 0
  if(!unit_discount) return ''
  else return (localLang != 'il' ? '-' : '') + unit_discount + '%'
}

function handleClick() {
  if(content.value.type === 'coupon') return
  if(itemIds.value.length > 1) {
    state.value.showIncentiveProductListDialog = true
  } else if(itemIds.value.length === 1) {
    // TODO: 判断商品时候在筛选范围内
    if(state.value.filterType != 'none') {
      state.value.filterType = 'none'
    }
    scrollToCartItem({
      id: itemIds.value[0],
      smooth: true,
      highlightAnimate: true,
    })
  }

  rootStore.dispatch('cart/clearIncentivePopover') // 关闭气泡和清除定时器
  return
}

function closeIncentivePopover() {
  const incentiveGapTime = rootStore.getters['cart/incentiveTimeConfig']?.incentiveGapTime || 1000 * 60
  if(state.value.showIncentivePopover) {
    state.value.showIncentivePopover = false // close popover
    if(state.value.incentivePopoverSwitchTimer) {
      clearTimeout(state.value.incentivePopoverSwitchTimer)
      state.value.incentivePopoverSwitchTimer = null
    }
    if(state.value.showIncentiveTimes >= 3 || state.value.showIncentiveTimes > (state.value.inducementSortList?.length || 0) - 1) return
    state.value.incentivePopoverSwitchTimer = setTimeout(() => {
      state.value.showIncentivePopover = true
    }, incentiveGapTime)
  }
}

function getAnalysisInformation() {
  if(content.value.type == 'oos') {
    const cartItemMap = state.value.cartItemMap
    return itemIds.value.map(id => cartItemMap[id]?.quantity)?.filter(q => q > 0).join(',')
  } else {
    return ''
  }
}

function getAnalysisAvailablePoint() {
  if(state.value.inducementSortList?.length) {
    return state.value.inducementSortList.slice(
      (state.value.showIncentiveTimes > 0 ? state.value.showIncentiveTimes - 1 : 0),
      4 - state.value.showIncentiveTimes
    ).map(item => item.type).join(',')
    // 1: 0,1,2 => 0-3
    // 2: 1,2 => 0-2
    // 3: 3 => 0-1
  }
  return ''
}

function getIncentiveData() {
  // if(true) {
  const IncentiveItems = state.value.inducementSortList
  const index = state.value.showIncentiveTimes
  if(IncentiveItems[index]) {
    const incentiveItem = IncentiveItems[index]
    // const incentiveItem = {
    //   bubbleTips: 'there is are cheaper. <b style=\"color:#fa6338;\">up to 48838.80 OFF!</b>',
    //   groupTitle: 'there is are cheaper. <b style=\"color:#fa6338;\">up to 48838.80 OFF!</b>',
    //   type: 'oos',
    //   products: ['1002673461833781290', '1002673461833781289']
    // }
    if(INCENTIVE_TYPES.includes(incentiveItem.type)) {
      if(incentiveItem.type == 'coupon') {
        state.value.incentivePopoverContent = {
          ...incentiveItem,
          ...(POPOVER_CONFIGS[incentiveItem.type] || {}),
          ...formatCouponData(incentiveItem.couponInfo)
        }
      } else {
        state.value.incentivePopoverContent = {
          ...incentiveItem,
          ...(POPOVER_CONFIGS[incentiveItem.type] || {})
        }
        state.value.incentiveItemIds = incentiveItem.products
      }
    }
  }
}

function formatCouponData(coupon) {
  if(!coupon) return {}
  if(isFreeShippingCoupon(coupon)) {
    if (coupon.realDiscountPrice.amount > 0) {
      if (coupon.apply_for == 9 && coupon.shipping_discount_type == 1) {
        return {
          couponDownText: 'SHEIN_KEY_PWA_14883',
          couponUpText: coupon.realDiscountPrice.amountWithSymbol,
          couponType: 'discount_shipping'
        }
      }
    } else if (coupon.realDiscountPrice.amount == 0) {
      if (coupon.apply_for == '5' || coupon.apply_for == '6' || (coupon.apply_for == '9' && coupon.shipping_discount_type == 0)) {
        return {
          couponDownText: 'SHEIN_KEY_PWA_15807',
          couponUpText: '',
          couponType: 'free_shipping'
        }
      }
    }
  } else if (coupon?.coupon_type_id == '1') {
    const other_coupon_rule = coupon?.other_coupon_rule || []
    return {
      couponDownText: 'SHEIN_KEY_PWA_14883',
      couponUpText: other_coupon_rule[other_coupon_rule.length - 1]?.price?.priceSymbol,
      couponType: 'discount_coupon'
    }
  } else {
    const other_coupon_rule = coupon?.other_coupon_rule || []
    return {
      couponDownText: 'SHEIN_KEY_PWA_14883',
      couponUpText: (Number(other_coupon_rule[other_coupon_rule.length - 1]?.price?.price || 0) * 100 / 100) + '%',
      couponType: 'discount_coupon'
    }
  }
  // return {
  //   couponDownText: 'SHEIN_KEY_PWA_15807',
  //   couponUpText: '',
  //   couponType: 'free_shipping' // free_shipping, discount_shipping, discount_coupon,
  // }
}

function getExtraAnalysisData(content) {
  if(content.type == 'lowest_price_30days') {
    return {
      actual_point_lowest: content?.actualPointLowest
    }
  } else {
    return {}
  }
}

defineExpose({
  imgs,
  state,
  locals,
  moreNums,
  itemIds,
  content,
})
</script>
<style lang="less" scoped>
.incentive-popover-content {
  display: flex;
  gap: 12/75rem;
  align-items: center;
}
.img-block {
  display: flex;
  gap: 8/75rem;
  .incentive-img-container {
    width: 76/75rem;
    height: 76/75rem;
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      display: block;
    }
    .img-discount {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 8px;
      color: @sui_color_promo_bg;
      background: var(---sui_color_discount, #FA6338);
      padding: 1/75rem 4/75rem;
    }
    .more-nums-mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        color: #fff;
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
}
.coupon-block {
  width: 120/75rem;
  height: 80/75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: contain;
  .coupon-up {
    font-weight: 860;
    text-align: center;
  }
  .coupon-down {
    font-size: 8px;
    font-weight: 700;
  }
}
.free_shipping, .discount_shipping {
  color: rgba(22, 158, 0, 1);
  background-image: url(https://img.ltwebstatic.com/images3_acp/2024/05/29/29/17169741487d35c0ea6f7c3e25207a824980b6e5f3.png);
  .coupon-down {
    color: rgba(22, 158, 0, 0.86);
  }
}
.free_shipping {
  .free-shipping-text {
    font-size: 10px;
    font-weight: 860;
    color: rgba(22, 158, 0, 1);
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
}
.discount_coupon {
  color: rgba(250, 99, 56, 1);
  background-image: url(https://img.ltwebstatic.com/images3_acp/2024/05/29/4e/17169741368156f49839a54ed3b121e95ce263b150.png);
  .coupon-down {
    color: rgba(255, 100, 56, 0.8);
  }
}
.tip-content {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-width: 284/75rem;
  overflow: hidden;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  white-space: normal;
  .content-left, .content-right {
    white-space: nowrap;
  }
  .content-left {
    line-height: unset !important; /* stylelint-disable-line declaration-no-important */
  }
  .content-text {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 400;
    b {
      font-weight: 700;
    }
  }
}
.incentive-countdown {
  display: inline;
}
.incentive-countdown ::v-deep .time-content {
  color: rgba(250, 99, 56, 1);
  display: inline;
  .count-number {
    background: rgba(250, 99, 56, 1);
    border-radius: 1px;
    color: #fff;
    padding: 0 2/75rem;
  }
}
</style>
