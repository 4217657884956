<template>
  <!-- 促销全场活动：满折、满减、返券 -->
  <div
    v-if="fullCourtMap.length > 0"
    class="header-platform"
  >
    <ClientOnly>
      <swiper-container
        :class="['cart-full-promotion', 'j-top-promotion-swiper-'+ mallCode]"
        init="false"
        direction="vertical"
        autoplay-delay="4000"
        autoplay-disable-on-interaction="true"
        observer="true"
        :dir="locals.GB_cssRight ? 'rtl' : 'ltr'"
      >
        <swiper-slide
          v-for="(item, index) in fullCourtMap"
          :key="'promotion-item-' + index"
          v-enterkey
          tabindex="0"
          role="button"
          :aria-label="item.tip"
          @click="handlePromotionItemClick(item,index)"
        >
          <div
            class="cart-full-promotion__item"
            :class="{'single-mall': isSingleMall}"
          >
            <div class="cart-full-promotion__item-content">
              <Icon
                v-show="getIconName(item) == 'sui_icon_selected_16px'"
                class="item-content-icon"
                :class="{ 'single-mall': isSingleMall }"
                name="sui_icon_selected_16px" 
              />
              <Icon
                v-show="getIconName(item) == 'sui_icon_couponbundle_16px'"
                class="item-content-icon"
                :class="{ 'single-mall': isSingleMall }"
                name="sui_icon_couponbundle_16px"
              />
              <Icon
                v-show="getIconName(item) == 'sui_icon_coupon_discount_16px_2'"
                class="item-content-icon"
                :class="{ 'single-mall': isSingleMall }"
                name="sui_icon_coupon_discount_16px_2"
              />
              <Icon
                v-show="getIconName(item) == 'sui_icon_coupon_discount_18px'"
                class="item-content-icon"
                :class="{ 'single-mall': isSingleMall }"
                name="sui_icon_coupon_discount_18px"
              />
              <div
                class="item-content-tip"
                :class="{'single-mall': isSingleMall}"
              >
                <div
                  :ref="'promotionTip'+index"
                  class="item-content-tip__txt"
                  v-html="item.tip"
                ></div>
                <div
                  v-if="processCount(item) < 100 && item.typeId == 21"
                  class="item-content-tip__process"
                >
                  <div
                    class="item-content-tip__process-bg"
                    :style="{ width: `${processCount(item)}%` }"
                  ></div>
                </div>
                <div
                  v-if="item.countDownTimeStamp"
                  class="client-only-height"
                >
                  <ClientOnly>
                    <count-down-time :time-stamp="item.countDownTimeStamp" />
                  </ClientOnly>
                </div>
              </div>
              <div
                v-if="showMore"
                v-enterkey
                class="cart_item-action"
                tabindex="0"
                role="button"
                @click.stop="handlePromotionItemActionClick(item)"
              >
                <i
                  class="sui_icon_more_20px"
                  :class="[iconPrefix]"
                ></i>
              </div>
              <div
                v-else-if="item.add"
                v-enterkey
                class="cart_item-action"
                tabindex="0"
                role="button"
                @click.stop="handlePromotionItemActionClick(item)"
              >
                <span>{{ language.SHEIN_KEY_PWA_15091 }}</span>
                <sui_icon_more_right_12px
                  size="12px"
                  :is-rotate="locals.GB_cssRight"
                />
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper-container>
    </ClientOnly>
    <ClientOnly>
      <s-drawer
        v-ada-modal="{
          show: show.drawer,
          first: () => {
            $refs.promotionDrawerHeaderRef.focus();
          },
        }"
        :visible.sync="show.drawer"
        animate-type="small"
        @close="closeDrawer"
      >
        <template slot="top">
          <span
            ref="promotionDrawerHeaderRef"
            tabindex="0"
            :aria-label="promotionTitle"
          >
            {{ promotionTitle }}
          </span>
        </template>
        <ul class="full-court__drawer-list">
          <li
            v-for="(item, index) in fullCourtDrawerMap"
            :key="'promotion-drawer-' + index"
            class="cart-top__promotionDrawer-item"
            tabindex="0"
            role="button"
            :aria-label="item.tip"
            @click="handlePromotionDrawerItemClick(item)"
          >
            <!--            <i-->
            <!--              v-if="item.icon"-->
            <!--              class="promotionDrawer-item-icon__left"-->
            <!--              :class="[item.icon]"-->
            <!--            ></i>-->
            <div class="cart-top__promotionDrawer-item-content">
              <span class="item-tips__wrapper">
                <i
                  v-if="item.typeId == 21 && processCount(index) >= 100"
                  :class="[iconPrefix]"
                  class="sui_icon_selected_16px"
                ></i>
                <span v-html="item.tip"></span>
              </span>
              <div
                v-if="processCount(item) < 100 && item.typeId == 21"
                class="item-content-tip__process"
              >
                <div
                  class="item-content-tip__process-bg"
                  :style="{ width: `${processCount(item)}%` }"
                ></div>
              </div>
              <ClientOnly>
                <count-down-time
                  v-if="item.countDownTimeStamp"
                  :time-stamp="item.countDownTimeStamp"
                />
              </ClientOnly>
            </div>
            <div
              v-if="item.add"
              v-enterkey
              class="promotionDrawer-item-action"
              tabindex="0"
              role="button"
            >
              <span>{{ language.SHEIN_KEY_PWA_15091 }}</span>
              <i
                class="sui_icon_more_right_12px"
                :class="[iconPrefix]"
              ></i>
            </div>
            <i
              v-else
            ></i>
          </li>
        </ul>
      </s-drawer>
    </ClientOnly>
  </div>
</template>

<script>
import ClientOnly from 'vue-client-only'
import { mapState, mapGetters, mapMutations } from 'vuex'
import { Autoplay } from 'swiper/modules'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { Icon, sui_icon_more_right_12px } from '@shein-aidc/icon-vue2'

function arrStep(arr, step) {
  if (step >= arr.length) return step
  const arrClone = [].concat(arr)
  const stepItems = arrClone.splice(0, step)
  return arrClone.concat(stepItems)
}
export default {
  name: 'FullCourtMap',
  components: {
    ClientOnly,
    Icon,
    sui_icon_more_right_12px
  },
  props: {
    mallCode: {
      type: String,
      default: ''
    },
    // 是否单mall 单mall展示在顶部位置，多mall展示在mall下
    isSingleMall: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      swiperEl: null,
      show: {
        drawer: false,
      },
      fullCourtDrawerMap: [],
      promotionTitle: null,
      showMore: false
    }
  },
  computed: {
    ...mapGetters('cart', [
      'mallFullCourtMap',
      'iconPrefix',
      'carts',
    ]),
    ...mapState('cart', [
      'language',
      'locals',
      'cartAbtInfo',
    ]),
    IS_RW() {
      return this.locals.IS_RW
    },
    fullSubtractAdd(){
      return this.fullCourtMap.find(item => item.typeId == 14)?.add
    },
    returnCouponAdd(){
      return this.fullCourtMap.find(item => item.typeId == 21)?.add
    },
    fullDiscountAllAdd(){
      return this.fullCourtMap.find(item => item.typeId == 26)?.add
    },
    fullCourtMap(){
      return this.mallFullCourtMap[this.mallCode] || []
    },
  },
  watch: {
    fullCourtMap: {
      deep: true,
      immediate: true,
      handler(n) {
        if (n && n.length > 1) {
          this.showMore = true
          this.$nextTick(() => {
            if (typeof window != 'undefined') {
              this.initSwiper()
            }
          })
        } else if(n && n.length == 1){
          this.$nextTick(()=>{
            const e = this.$refs[`promotionTip0`] && this.$refs[`promotionTip0`][0] || {}
            if(e.scrollHeight > e.clientHeight) {
              this.showMore = true
            } else {
              this.showMore = false
            }
          })
        }
      }
    },
    fullSubtractAdd: {
      immediate: true,
      handler(n, o) {
        if ( n && n != o ) {
          this.handleCartPromotionAddClick(14)
        }
      }
    },
    returnCouponAdd: {
      immediate: true,
      handler(n, o) {
        if ( n && n != o ) {
          this.handleCartPromotionAddClick(21)
        }
      }
    },
    fullDiscountAllAdd: {
      immediate: true,
      handler (n, o) {
        if ( n && n != o ) {
          this.handleCartPromotionAddClick(26)
        }
      }
    },
  },
  methods: {
    ...mapMutations('cart', ['updateState' ]),
    processCount (item) {
      return item?.processCount || 0
    },
    /**
     * Promotion drawer item click
     */
    handlePromotionDrawerItemClick(item) {
      this.closeDrawer()
      if (item.add) {
        this.handleSupplement(item.typeId, item.promotion_id)
      }
    },
    handlePromotionItemActionClick(item){
      this.handlePromotionItemClick(item)
    },
    handlePromotionItemClick(item) {
      this.promotionTitle = item.title
      if(this.fullCourtMap.length > 1) {
        this.promotionTitle = this.language.SHEIN_KEY_PWA_17051
      }

      if (this.fullCourtMap.length > 1 || this.showMore) return this.openDrawer(item)

      if (item.add) {
        this.handleSupplement(item.typeId, item.promotion_id)
      }
    },
    // 展示免邮凑单
    handleSupplement(addType, promotion_id) {
      this.handleCartPromotionAddClick(addType, promotion_id, true)
      if([14, 26, 21].includes(addType)) {
        this.openToAddOn({
          typeId: addType,
          promotion_id: promotion_id,
        })
      }
    },
    handleCartPromotionAddClick (
      typeId,
      pid,
      isClick = false
    ) {
      if (typeof window == 'undefined') return
      if ( [14, 21, 26].includes(typeId) ) {
        daEventCenter.triggerNotice({
          daId: isClick ? '1-8-5-2' : '1-8-5-1',
          extraData: {
            id: pid,
            typeId: typeId,
            isFullPromotion: 1,
            is_member_gift: 0,
          },
        })
      }
    },
    openDrawer(item) {
      this.show.drawer = true

      if (this.swiperEl?.swiper) {
        this.fullCourtDrawerMap = arrStep(
          this.fullCourtMap,
          this.swiperEl.swiper.activeIndex
        )
        this.swiperEl.swiper.autoplay?.stop()
      } else {
        this.fullCourtDrawerMap = []
        this.fullCourtDrawerMap.push(item)
      }
    },
    closeDrawer() {
      this.show.drawer = false
      if (this.swiperEl?.swiper) this.swiperEl.swiper.autoplay?.start()
    },
    initSwiper() {
      if (!this.swiperEl) this.swiperEl = document.querySelector('.j-top-promotion-swiper-' + this.mallCode)
      Object.assign(this.swiperEl, {
        modules: [Autoplay],
        // direction: 'vertical',
        // autoplay: {
        //   delay: 4000,
        //   disableOnInteraction: true,
        // },
        // // loop: true,
        // observer: true,
      })
      this.swiperEl.initialize()
      this.resizeSwiper()
    },
    /**
     * 异步修改 dom 后重设 Swiper 高度
     */
    resizeSwiper() {
      let maxSliderHeight = 0
      let ele = '.cart-full-promotion__item'
      this.$nextTick(() => {
        const slidersContent = document.querySelectorAll(
          `.j-top-promotion-swiper-${this.mallCode} ${ele}-content`
        )
        slidersContent.forEach((item) => {
          const slider = $(item).parent(ele)
          const itemPaddingTop = parseInt($(slider).css('padding-top'), 10)
          const itemPaddingBottom = parseInt(
            $(slider).css('padding-bottom'),
            10
          )
          const itemHeight = $(item).height()
          const computedHeight =
            itemPaddingTop + itemPaddingBottom + itemHeight
          if (computedHeight > maxSliderHeight)
            maxSliderHeight = computedHeight
        })
        $(`.j-top-promotion-swiper-${this.mallCode}`).height(maxSliderHeight)
      })
    },
    getIconName(item) {
      if (item.typeId != 21) {
        return 'sui_icon_activity_18px'
      }

      if (this.processCount(item) >= 100 || item.diff_us_amount == 0 || item.range > 0) {
        return 'sui_icon_selected_16px'
      }

      if (item.couponNum > 1) {
        return 'sui_icon_couponbundle_16px'
      }

      return this.IS_RW ? 'sui_icon_coupon_discount_16px_2' : 'sui_icon_coupon_discount_18px'
    },

    openToAddOn(promotion) {
      if (!promotion || !promotion.promotion_id) return
      this.updateState({
        key: 'addOnInfo', 
        value: {
          show: true,
          promotionId: promotion.promotion_id,
        }
      })
    }
  }
}
</script>

<style lang="less">

.cart-top__promotion{
  margin: 20/75rem 16/75rem;
  overflow: hidden;
  &.cart-top__promotion-platform{
    margin: 0;
    max-height: 156/75rem;
    .cart-top__promotion-item{
      padding: 0;
      background-color: @sui_color_promo_bg;
    }
  }
  .rw-promotion-item + .rw-promotion-item{
    margin-top: 20/75rem;
  }
  &.cart-top__promotion-sh{
    max-height: 278/75rem;
  }
}
.cart-top__promotion-item {
  padding: 32/75rem 24/75rem;
  background-color: #FFFFFF;
}
.cart-top__promotion-wrapper {
  z-index: @zindex-out;
}
.promotion_title {
  font-size: 14px;
  color: #222;
  font-weight: 700;
}
.cart-top__promotion-item-content {
  padding: 32/75rem 24/75rem;
  background: @sui_color_promo_bg;
  box-sizing: border-box;
  width: 100%;
  em {
    color: @sui_color_welfare_dark;
  }
  .promotion-tip{
    color: #222;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    &.mall-promotion-tip{
      padding-right: 24/75rem;
    }
  }
  .time-content{
    font-weight: bold;
  }
  &.multi-mall-promotion{
    padding: 24/75rem 24/75rem 24/75rem 22/75rem;
    .promotion-tip-time{
      .margin-r(.32rem);
      flex: 1;
      text-align: left;
    }
    .promotion-item-icon{
      margin-right: 22/75rem;
      font-size: 18px;
    }
  }
}

/* CartTopPromotionDrawer 顶部促销提示共融抽屉弹窗
----------------------------------------------------------------*/
.full-court__drawer-list{
  padding: 0 24/75rem 96/75rem;
}
.cart-top__promotionDrawer-item {
  position: relative;
  padding: 32/75rem 0;
  border-bottom: 1px solid #E5E5E5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .promotionDrawer-item-action{
    font-size: 12px;
    white-space: nowrap;
    font-weight: bold;
  }
  .cart-top__promotionDrawer-item-content {
    color: #222222;
    .margin-r(.32rem);
    em {
      color: @sui_color_welfare_dark;
    }
    .item-tips__wrapper {
      display: flex;
      align-items: center;
      i {
        .margin-r(.2133rem);
      }
    }
  }
  .count-down-time{
    margin-top: 20/75rem;
  }
}

/* 新的全场活动样式 */
.cart-full-promotion{
  display: block;
  margin: 0;
  // max-height: 156/75rem;
  background-color: @sui_color_promo_bg;
  &__item{
    padding: 20/75rem;
    height: auto !important; /* stylelint-disable-line declaration-no-important */
    background-color: @sui_color_promo_bg;
    &.single-mall{
      padding: 20/75rem 24/75rem;
    }
  }
  &__item-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item-content-icon{
      font-size: 16px;
      margin-right: 20/75rem;
      &.single-mall{
        margin-right: 16/75rem;
      }
      width: 32/75rem;
      height: 32/75rem;
      line-height: 1;
    }
    .item-content-tip{
      padding-right: 20/75rem;
      flex: 1;
      &.single-mall{
        padding-right: 24/75rem;
      }
      .item-content-tip__txt{
        color: #222;
        font-size: 12px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
  .item-content-tip__txt{
    em{
      color: @sui_color_welfare_dark;
    }
  }
}
.item-content-tip__process {
  width: 100%;
  height: .08rem;
  background-color: @sui_color_gray_light2;
  border-radius: 2px;
  margin: .1067rem 0;
}
.item-content-tip__process-bg {
  height: .08rem;
  background-color: @sui_color_main;
  border-radius: 2px;
  transition: width .5s linear;
}
</style>
