<template>
  <div class="cart-item-similar">
    <div class="title">
      {{ language.SHEIN_KEY_PWA_25938 }}
    </div>
    <div class="content">
      <div 
        v-for="(item, index) in similarItems"
        :key="item.goods_sn"
        v-expose="analysisData('1-8-3-29', item)"
        v-tap="analysisData('1-8-3-30', item)"
        class="item"
        @click="handleQuickAdd(item, index)"
      >
        <TransformImg
          class="image"
          :use-origin-img="true"
          :img-src="item.goods_img"
          fetchpriority="high"
        />
        <div
          v-show="item.unit_discount != 0"
          class="unit-discount"
          :class="{
            'fs-discount': isFlashBuy(item),
            'more-discount': isDiscountShow(item),
          }"
          aria-hidden="true"
        >
          <div v-if="isDiscountShow(item)">
            <span>
              {{ locals.lang != 'il' ? '-' : '' }}{{ item.unit_discount }}%
            </span>
            <span class="originl-discount">
              {{ locals.lang != 'il' ? '-' : '' }}{{ item.original_discount || 0 }}%
            </span>
          </div>
          <span v-else>
            {{ locals.lang != 'il' ? '-' : '' }}{{ item.unit_discount }}%
          </span>
        </div>

        <div 
          class="price"
          :class="{
            'is-discount': item.unit_discount != 0,
          }"
        >
          {{ item.salePrice && item.salePrice.amountWithSymbol }}
        </div>
      </div>
      <div 
        v-expose="analysisData('1-8-3-31')"
        v-tap="analysisData('1-8-3-32')"
        class="item more"
        @click="handleMore"
      >
        <i class="suiiconfont sui_icon_viewmore_right_40px"></i>
        <div class="tips">
          {{ language.SHEIN_KEY_PWA_26159 }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TransformImg from 'public/src/pages/components/transformImg/index.vue'
import { mapState } from 'vuex'

export default {
  name: 'CartItemSimilar',
  components: {
    TransformImg,
  },
  props: {
    cartItem: {
      type: Object,
      default() { return {} }
    },
    similarItems: {
      type: Array,
      default() { return [] }
    }
  },
  computed: {
    ...mapState('cart', [
      'language',
      'locals',
      'cartAbtInfo',
    ]),
  },
  methods: {
    handleQuickAdd(item, index) {
      const { goods_id, mall_code } = item
      this.$quickAdd.open({
        goods_id,
        index,
        mallCode: mall_code,
        quantity: 1,
        isClickToDetail: false, // 商详跳转
        clickCallBack: {
          cart: (data) => {
            if (data?.code == '0') {
              appEventCenter.$emit('update-cart', data)
            }
          },
        },
        analysisConfig: {
          code: 'cart',
          from: 'cart',
          sa: {
            activity_from: 'similar_items',
            style: 'popup', 
          }
        }
      })
    },
    handleMore() {
      this.$emit('handleMoreSimilar', {
        similarFrom: 'out_of_stock',
        recAdp: this.similarItems.map(item => item?.goods_id).join(',') || '',
      })
    },
    analysisData(id, item) {
      return {
        id: id,
        data: {
          item,
          main_goods_id: this.cartItem.product.goods_id,
          abt: this.cartAbtInfo?.SheinFindSimilar,
        },
      }
    },
    isFlashBuy(item) { // 闪购
      return item.promotionInfo?.some(v => [10, 11].includes(+v.typeId))
    },
    isDiscountShow(item) {
      return item.original_discount && 
        item.promotionInfo?.some(v => v.typeId == 3) && 
        item.original_discount != item.unit_discount
    },
  },
}
</script>

<style lang="less" scoped>
.cart-item-similar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .title {
    line-height: 14px;
    color: #222;
  }
  .content {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    .more {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      background-color: #F6F6F6;
      i {
        font-size: 24px;
        line-height: 24px;
      }
    }
    .tips {
      font-weight: bold;
    }
  }
  .item {
    position: relative;
    width: 112/75rem;
    height: 112/75rem;
    margin-right: 12/75rem;
    background: blue;
    &:last-child {
      margin-right: 0;
    }
    .image {
      width: 100%;
      height: 100%;
    }
    .unit-discount {
      position: absolute;
      right: auto;
      text-align: center;
      color: @color_white_mirror;
      direction: ltr /* rtl:ignore */;
      .font-dpr(24px);
      /* sh:begin */
      min-width: 2.75em;
      line-height: 1.35;
      top: 4px;
      left:0 /* rtl:ignore */;
      right: unset /* rtl:ignore */;
      background: #333;
      /* sh:end */
      /* rw:begin */
      padding: 0 5/75rem;
      right: unset /* rtl:ignore */;
      line-height: 32/75rem;
      background: @sui_color_main;
      top: 0;
      left: 0 /*rtl:ignore*/;
      color:@sui_color_white;
      font-family: Adieu-Bold, Adieu;
      font-weight: bold;
      font-size: 9px;
    }
    .fs-discount {
      background: @sui_color_flash;
      color: #333;
      /* rw:begin */
      color:  @sui_color_white;
    }
    .more-discount {
      background: @sui_color_promo;
      /* sh:begin */
      width: 32px;
      min-width: auto;
      /* sh:end */
      /* rw:begin */
      color: @sui_color_white;
      background: @sui_color_main;
    }
    .originl-discount {
      text-decoration: line-through;
      opacity: 0.8;
      display: block;
      /* rw:begin */
      font-family: Adieu-Regular, Adieu;
      font-weight: 400;
    }
    .price {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: rgba(246, 246, 246, 0.80);
      padding: 4/75rem 12/75rem;
      line-height: 1;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      color: @sui_color_gray_dark1;
    }
    .is-discount {
      color: @sui_color_discount;
    }
  }
}
</style>
