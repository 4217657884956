
import { Expose } from '@shein-aidc/basis-expose'
import { isNull } from '@shein/common-function'

/**
 * 购物车JSX曝光方案
 * @param {Array} configs 配置项: [{ el, once, cb }]
 */
export const withExpose = (configs) => {
  if (!configs || !configs.length) return
  configs.forEach(v => {
    if (!v.el) return

    let exposeItem = new Expose({
      mask: v.mask,
      delay: isNull(v.delay) ? 300 : v.delay, // 兼容0毫秒
    })
    exposeItem.observe({
      elements: v.el,
      once: !!v.once,
    }, () => {
      v.cb && v.cb()
    })
  })
}
