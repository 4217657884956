import schttp from 'public/src/services/schttp'
import { asyncLoadFile } from '@shein/common-function'
import CheckAccountExist from 'public/src/pages/common/paypal/checkAccountExist.js'
import { LoginPatterRegex } from 'public/src/pages/login/util.js'

class PaypalSDK {
  constructor(lang = '') {
    this.paypalSdkLoadSuccess = {}
    this.clientToken = ''
    this.paypalSdkTimeout = null
    this.paypalGaBtn = null
    this.paypalDeviceId = '',
    this.renderPaypalBtnCb = null,
    this.clickEvt = null
    this.channelSessionParams = null
  }

  /**
   * 获取paypal client token, 用于paypal sdk初始化
   * @param {Object} data {
   *   shipCountry: 'us',
   *   orderAmount: '100',
   *   orderCurrency: 'USD',
   *   payMethodList: ['PayPal-GApaypal'],
   *   orderDetails: [{ businessModel: 0, companyId: 1 }]
   * }
   * @param {Function} cb onApprove授权成功回调函数
   * @param {Function} renderPaypalBtnCb 渲染paypal按钮回调函数
   * @param {Function} clickEvt 点击paypal按钮回调函数
   */
  async getClientToken(data, cb, renderPaypalBtnCb = null, clickEvt = null) {
    this.renderPaypalBtnCb = renderPaypalBtnCb
    this.clickEvt = clickEvt
    this.channelSessionParam = data
    schttp({
      method: 'POST',
      url: '/api/checkout/channelSession/create',
      data
    }).then(res => {
      if (res.code == 0) {
        const sessions = res?.info?.sessions || []
        sessions.forEach(item => {
          if (item.payMethod === 'PayPal-GApaypal') {
            this.clientToken = item.clientToken
            this.initPaypalSdk(cb, data.shipCountry, data.orderCurrency)
          }
        })
      }
    })
  }

  async loadPaypalScript() {
    if(this.paypalSdkLoadSuccess['PayPal-GApaypal']) return
    const paypalChannelSdks = {
      'PayPal-GApaypal': [
        //Load the client component
        'https://js.braintreegateway.com/web/3.85.2/js/client.min.js',
        //Load the PayPal Checkout component
        'https://js.braintreegateway.com/web/3.85.2/js/paypal-checkout.min.js',
        //手机设备数据
        'https://js.braintreegateway.com/web/3.85.2/js/data-collector.min.js'
      ],
      'PayPal-Venmo': [
        //Load the client component
        'https://js.braintreegateway.com/web/3.85.2/js/client.min.js',
        //Load the PayPal Checkout component
        'https://js.braintreegateway.com/web/3.85.2/js/venmo.min.js',
        //手机设备数据
        'https://js.braintreegateway.com/web/3.85.2/js/data-collector.min.js'
      ]
    }
    let paypalSdks = paypalChannelSdks['PayPal-GApaypal'] || []
    let loadCount = 0
    const arr = []
    paypalSdks.forEach(src => {
      arr.push(
        asyncLoadFile({
          label: 'script',
          attrs: { src }
        }).then(() => {
          loadCount++
        })
      )
    })

    await Promise.all(arr)
      .then(() => {
        if (loadCount == paypalSdks.length) {
          this.paypalSdkLoadSuccess['PayPal-GApaypal'] = true
        }
      })
      .catch(err => {
        this.paypalSdkLoadSuccess['PayPal-GApaypal'] = false
      })
  }

  // paypal 初始化sdk
  async initPaypalSdk(cb, shipCountry, orderCurrency) {
    const _this = this
    await this.loadPaypalScript()
    // paypal sdk 定时器
    this.paypalSdkTimeout = setTimeout(() => {
      if (this.paypalGaBtn) {
        console.log('paypal sdk timeout')
      }
    }, 20 * 1000)

    await window.braintree.client
      .create({
        authorization: this.clientToken
      })
      .then(function (clientInstance) {
        //device data
        window.braintree.dataCollector
          .create({
            client: clientInstance
          })
          .then(function (dataCollectorInstance) {
            _this.paypalDeviceId = dataCollectorInstance?.deviceData
          })

        //初始化组件
        console.log('Initialize components')
        // Create a PayPal Checkout component.
        return window.braintree.paypalCheckout.create({
          client: clientInstance
        })
      })
      .then(function (paypalCheckoutInstance) {
        console.log('Load the PayPal JS SDK')
        // Load the PayPal JS SDK (see Load the PayPal JS SDK section)
        return paypalCheckoutInstance
          .loadPayPalSDK({
            currency: orderCurrency,
            intent: 'capture'
          })
          .then(data => {
            return data
          })
      })
      .then(function (paypalCheckoutInstance) {
        return _this.renderPaypalBtn(paypalCheckoutInstance, cb, shipCountry)
      })
    clearTimeout(this.paypalSdkTimeout)
  }

  // 渲染paypal按钮
  renderPaypalBtn(paypalCheckoutInstance, cb, shipCountry) {
    const _this = this
    const renderButtons = [
      {
        type: 1,
        id: `#paypal-button`
      }
    ]

    // The PayPal button will be rendered in an html element with the ID
    // `paypal-vault-button`. This function will be called when the PayPal button
    // is set up and ready to be used
    return Promise.all(
      renderButtons.map(buttonId => {
        const dataScene = 'order'
        // const style = _this.setBtnStyleData(buttonId?.id?.replace(/#/g, ''))
        return paypal
          .Buttons({
            fundingSource: paypal.FUNDING.PAYPAL,
            // style:
            //   buttonId.type == 1 && dataScene == 'order'
            //     ? { ...style, height: 40 }
            //     : style,

            createOrder: function () {
              // const shippingAddressOverride = {}
              const config = {
                flow: 'checkout', // Required
                amount: _this.channelSessionParam.orderAmount, // Required
                currency: _this.channelSessionParam.orderCurrency, // Required, must match the currency passed in with loadPayPalSDK
                intent: 'capture',
                enableShippingAddress: true,
                shippingAddressEditable: true
              }
              return paypalCheckoutInstance.createPayment(config).then(data => {
                return data
              })
            },
            onClick: async function (data, actions) {
              if(typeof _this.clickEvt == 'function') {
                let result = _this.clickEvt()
                if(result?.show) {
                  _this.channelSessionParam.orderAmount = result.orderAmount
                  return actions.resolve()
                } else {
                  return actions.reject()
                }
              }
              return actions.resolve()
            },
            onApprove: function (data) {
              return paypalCheckoutInstance.tokenizePayment(
                data,
                function (err, payload) {
                  // Submit `payload.nonce` to your server
                  // TODO
                  // const email = 'www0030019@gmail.com'
                  // const email = 'ljmzc1@qq.com'
                  // const email = 'qxq036@gmail.com'
                  // const email = 'dfegeg@gmail.com'
                  const email = payload.details.email
                  // 如果邮箱为空，返回null
                  if(!email || email?.length == 0) {
                    cb(null)
                    return
                  }
                  // 如果paypal返回的邮箱不满足邮箱正则，返回空
                  if(!LoginPatterRegex.test(email)) {
                    cb(null)
                    return
                  }

                  CheckAccountExist.checkAccount(email, shipCountry, '', cb)
                }
              )
            },

            onCancel: function (data) {},
            onError: function (err) {}
          })
          .render(buttonId.id)
      })
    )
      .then(() => {
        _this.paypalGaBtn = true
        typeof this.renderPaypalBtnCb == 'function' && this.renderPaypalBtnCb(true)
        clearTimeout(_this.paypalSdkTimeout)
      })
      .catch(err => {
        _this.paypalGaBtn = false
        typeof this.renderPaypalBtnCb == 'function' && this.renderPaypalBtnCb(false)
        clearTimeout(_this.paypalSdkTimeout)
      })
  }
}

export default new PaypalSDK()
