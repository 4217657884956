import schttp from 'public/src/services/schttp'
import { InitFtokenPromise } from 'public/src/pages/common/ftoken/index'
import verifyManager from '@shein-risk/si_verify'
const IS_SSR = typeof window == 'undefined'
// 初始化风控sdk
const initVerifyManager = async () => {
  let ftoken = !IS_SSR && gbCommonInfo?.ftoken
  if (!ftoken) {
    ftoken = await InitFtokenPromise(true)
  }
  verifyManager.init({
    axiosInstance: schttp, // 你的HTTP实例对象
    axiosConfig: {
      headers: { 'x-ftoken': ftoken } // 风控需要 ftoken
    }
  })
}

// 通过email检测账户是否注册
class CheckAccountExist {
  constructor(lang = '') {
  }

  async checkAccount(email, shipCountry, validate_token, cb) {
    if(!email) {
      cb(null)
      return
    }

    let res = await schttp({
      url: '/api/auth/chekAccountExist/get',
      method: 'post',
      data: { alias: email, alias_type: 1, area_abbr: shipCountry, page_source: 'cart_paypal_checkout', 'uberctx-validate-token': validate_token || '' }
    })
    // 从paypal返回到shein 购物车页面，paypal正常回传邮箱，接口被风控（402922),则将extend_info节点下的信息直接透传给到风控组件
    if(res?.code === '402922') {
      // 初始化风控sdk
      await initVerifyManager()
      // 触发风控
      if(!res?.info){
        return
      }
      verifyManager.doVerify({
        ...res.info,
        validate_channel: '1' // 0.pc  1.pwa 
      }, riskResult => {
        if (riskResult.isSuccess) {
          const { validate_token = '' } = riskResult?.info || {}
          // 当风控组件完成后，需要进行二次调用“登录注册前定位账号接口”（正常弹起登录注册窗口即通过）
          this.checkAccount(email, shipCountry, validate_token, cb)
          return
        }
        // 挑战失败或者关闭
        res = {
          code: -1,
          info: riskResult,
          msg: '风控验证失败'
        }
        cb(res, email)
      })
    }
    cb(res, email)
  }
}

export default new CheckAccountExist()
