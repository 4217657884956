var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
    id: '1-8-3-59',
    data: {
      actual_point: _setup.content.type,
      available_point: _setup.getAnalysisAvailablePoint(),
      information: _setup.getAnalysisInformation(),
      ..._setup.getExtraAnalysisData(_setup.content),
    }
  }),expression:"{\n    id: '1-8-3-59',\n    data: {\n      actual_point: content.type,\n      available_point: getAnalysisAvailablePoint(),\n      information: getAnalysisInformation(),\n      ...getExtraAnalysisData(content),\n    }\n  }"},{name:"tap",rawName:"v-tap",value:({
    id: '1-8-3-60',
    data: {
      actual_point: _setup.content.type,
      information: _setup.getAnalysisInformation(),
      ..._setup.getExtraAnalysisData(_setup.content),
    }
  }),expression:"{\n    id: '1-8-3-60',\n    data: {\n      actual_point: content.type,\n      information: getAnalysisInformation(),\n      ...getExtraAnalysisData(content),\n    }\n  }"}],ref:"observeMeRef",staticClass:"incentive-popover-content",on:{"click":_setup.handleClick}},[(_setup.content.type != 'coupon')?_c('div',{staticClass:"popover-left img-block"},_vm._l((_setup.imgs),function(imgItem,index){return _c('div',{key:index,staticClass:"incentive-img-container"},[_c('img',{staticClass:"popover-img lazyload",attrs:{"src":_setup.locals.LAZY_IMG,"data-src":imgItem.img_url,"alt":imgItem.goods_name,"aria-hidden":""}}),_vm._v(" "),(imgItem.discount)?_c('div',{staticClass:"img-discount"},[_vm._v("\n        "+_vm._s(imgItem.discount)+"\n      ")]):_vm._e(),_vm._v(" "),(_setup.imgs.length > 1 && index === _setup.imgs.length - 1 && _setup.moreNums)?_c('div',{staticClass:"more-nums-mask"},[_c('span',[_vm._v(_vm._s(_setup.moreNums))])]):_vm._e()])}),0):_c('div',{class:[
      'popover-left',
      'coupon-block',
      _setup.content.couponType 
    ]},[(_setup.content.couponType == 'free_shipping')?[_c('div',{staticClass:"free-shipping-text"},[_vm._v("\n        "+_vm._s(_setup.language[_setup.content.couponDownText])+"\n      ")])]:[_c(_setup.ShrinkText,{staticClass:"coupon-up",attrs:{"content":_setup.content.couponUpText,"max-width":54,"options":{
          fontSize: 14,
          step: 1,
          minFontSize: 10
        }}}),_vm._v(" "),_c('div',{staticClass:"coupon-down"},[_vm._v("\n        "+_vm._s(_setup.language[_setup.content.couponDownText])+"\n      ")])]],2),_vm._v(" "),_c('div',{staticClass:"tip-content"},[(_setup.content.iconName)?_c(_setup.Icon,{staticClass:"content-left",attrs:{"name":_setup.content.iconName,"size":"14px","color":_setup.content.color,"is-responsive-name":""}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"content-text"},[_c('span',{domProps:{"innerHTML":_vm._s(_setup.content.bubbleTips)}}),_vm._v(" "),(_setup.content.type != 'coupon')?_c(_setup.Icon,{attrs:{"name":"sui_icon_more_right_16px_2","size":"10px"}}):_vm._e()],1),_vm._v(" "),_c('span',{staticClass:"content-right"},[(_setup.content.countDown >= 0)?_c('CountDownTime',{staticClass:"incentive-countdown",attrs:{"time-stamp":Number(_setup.content.countDown),"not-reload":"","not-hide":"","font-size":"10px"}}):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }