<template>
  <!-- 包一层div，用于transiont高度的动画，最外层padding影响过渡卡顿 -->
  <div>
    <div
      v-enterkey
      class="free-ship"
      :class="{'free-ship_activity': isShowCartActivityColor && !isInModal}"
      :da-event-click="firstFreeShipping.firstFreeShippingTip ? '' : '1-8-7-12'"
      :data-shipping_free_type="shippingFreeType"
      :data-mall_code="isPlatForm ? '' : singleMallCode"
      :data-show-position="floatCountDown ? 2 : 3"
      :data-show-way="showWay"
      @click="clickCartTip"
    >
      <slot>
        <i
          class="suiiconfont item-icon"
          :class="[!topShippingInfo.showShipSupplement || isShowSatisfyFreeTip ? 'sui_icon_selected_16px' : 'sui_icon_shipping_green_18px sui_icon_shipping_18px']"
          aria-hidden="true"
        ></i>
      </slot>
      <transition 
        name="freedisappear"
        mode="out-in"
      >
        <div
          v-if="isShowSatisfyFreeTip || (firstFreeShipping && firstFreeShipping.firstFreeShippingTip) || shippingTip"
          class="ship-content"
          :class="{'ship-content_activity': isShowCartActivityColor && !isInModal}"
        >
          <!-- 满足门槛的免邮 -->
          <template v-if="isShowSatisfyFreeTip">
            <div 
              class="tips" 
              v-html="isShowSatisfyFreeTip"
            >
            </div>
          </template>
          <!-- 首单免邮 -->
          <template v-else-if="firstFreeShipping && firstFreeShipping.firstFreeShippingTip">
            <div 
              class="tips" 
              v-html="firstFreeShipping.firstFreeShippingTip"
            >
            </div>
            <div
              v-if="firstFreeShipping.showCountDown && firstFreeShipping.countDownTime > 0"
              :class="{'float-count-down': floatCountDown}"
            >
              <ClientOnly>
                <count-down
                  :is-left-time="true"
                  :time-stamp="firstFreeShipping.countDownTime"
                  :type="countDownType"
                />
              </ClientOnly>
            </div>
          </template>
          <!-- 不满足门槛的免邮 -->
          <template v-else>
            <div 
              class="tips"
              v-html="shippingTip" 
            >
            </div>
          </template>
        </div>
      </transition>
      <transition 
        name="freedisappear"
        mode="out-in"
      >
        <a
          v-if="topShippingInfo.showShipSupplement"
          class="cart-tip__operation"
          tabindex="0"
          :aria-label="language.SHEIN_KEY_PWA_15091"
        >
          {{ language.SHEIN_KEY_PWA_15091 }}
          <i
            class="sui_icon_more_right_12px"
            :class="[iconPrefix]"
            aria-hidden="true"
          ></i>
        </a>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import countDown from './countDown.vue'
import ClientOnly from 'vue-client-only'

export default {
  components: {
    ClientOnly,
    countDown,
  },
  props: {
    floatCountDown: {
      type: Boolean,
      default: false,
    },
    // 是否在弹窗中
    isInModal: {
      type: Boolean,
      default: false,
    },
    showWay: {
      type: String,
      default: '',
    }
  },
  computed: {
    ...mapState('cart', ['isPlatForm', 'language', 'mallList', 'mallShippingInfo']),
    ...mapGetters('cart', ['mallShippingAnalysisParam', 'topShippingInfo', 'firstFreeShipping', 'singleMallCode', 'iconPrefix', 'isShowCartActivityColor', 'cartShippingTop']),
    shippingTip() {
      return this.topShippingInfo.shippingTip || ''
    },
    shippingFreeType() {
      if ( this.isPlatForm ) return 0
      return 3
    },
    shippingAnalysisParam() {
      let type = this.isPlatForm ? 'platform' : this.singleMallCode
      return this.mallShippingAnalysisParam[type] || {}
    },

    isShowSatisfyFreeTip () {
      if (!this.isPlatForm) {
        if (this.topShippingInfo && this.topShippingInfo.shippingTip && !this.topShippingInfo.showShipSupplement) {
          return this.topShippingInfo.shippingTip
        } else {
          return ''
        }
      }
      const { platform } = this.mallShippingInfo
      // 平台提示语
      const platformTips = platform?.showShipSupplement ? '' : (platform?.shippingTip || '')
      // mall级
      let flag = 0
      this.mallList.forEach(mall => {
        let mall_shipping = this.mallShippingInfo[mall.mall_code]
        if((mall_shipping?.shippingTip || mall_shipping?.firstFreeShipping?.firstFreeShippingTip) && !mall_shipping?.showShipSupplement){
          flag++
        }
      })
      const notPlatfromTips = flag == this.mallList.length ? this.language.SHEIN_KEY_PWA_20345 : ''
      return notPlatfromTips || platformTips
    },
    countDownType() {
      return this.isShowCartActivityColor && !this.isInModal ? 'black' : (this.floatCountDown ? 'green' : 'white')
    },
  },
  watch: {
    shippingTip: {
      immediate: true,
      handler(n) {
        if ( n && typeof window != 'undefined') {
          const { showShipSupplement, isLadder } = this.topShippingInfo
          if (!this.firstFreeShipping.firstFreeShippingTip && (showShipSupplement || isLadder) ) {
            daEventCenter.triggerNotice({
              daId: '1-8-7-11',
              extraData: {
                shipping_free_type: this.shippingFreeType,
                mall_code: this.isPlatForm ? '' : this.singleMallCode,
                show_position: this.floatCountDown ? 2 : 3,
                show_way: this.showWay,
              },
            })
          }
        }
      }
    },
    'topShippingInfo.showShipSupplement': {
      immediate: true,
      handler(n) {
        if ( n && typeof window != 'undefined' && !this.firstFreeShipping.firstFreeShippingTip) {
          const { isLadder } = this.topShippingInfo
          daEventCenter.triggerNotice({
            daId: isLadder ? '1-8-5-5' : '1-8-5-3',
            extraData: {
              ...this.shippingAnalysisParam,
              mall_code: this.isPlatForm ? '' : this.singleMallCode,
              show_position: this.cartShippingTop ? 1 : (this.floatCountDown ? 2 : 3),
              show_way: this.showWay,
            },
          })
        }
      }
    }
  },
  methods: {
    ...mapMutations('cart', ['updateState' ]),
    clickCartTip() {
      if ( !this.topShippingInfo.showShipSupplement ) return

      const { isLadder } = this.topShippingInfo
      daEventCenter.triggerNotice({
        daId: isLadder ? '1-8-5-6' : '1-8-5-4',
        extraData: {
          ...this.shippingAnalysisParam,
          mall_code: this.isPlatForm ? '' : this.singleMallCode,
          show_position: this.floatCountDown ? 2 : 3,
          show_way: this.showWay,
        },
      })

      // 首单免邮展示添加商品弹窗
      if ( this.topShippingInfo.showShipSupplement && this.firstFreeShipping.firstFreeShippingTip ) {
        this.handleSupplement(998)
        return
      }

      this.handleSupplement(999)
    },
    // 展示新版免邮活动凑单弹窗（wiki.pageId = 1245676638）
    handleSupplement(addType) {
      this.updateState({ key: 'freeShippingAddOnType', value: this.isPlatForm ? 'platform' : this.singleMallCode })
      this.updateState({ key: 'isMoreMallFreeShippingAddOn', value: false })
      this.$emit('addOn', { promotion_id: addType })
    },
  },
}
</script>

<style lang="less" scoped>
.free-ship {
  padding: .27rem .32rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: @sui_color_safety_bg;
  // color: @sui_color_safety;
  &_activity {
    border-radius: 12/37.5rem 12/37.5rem 0 0;
    background: @sui_color_safety;
    color: @sui_color_white;
    .cart-tip__operation {
      color: @sui_color_white;
    }
  }
  .item-icon {
    font-size: 16px !important;/* stylelint-disable-line declaration-no-important */
    line-height: 1;
    margin-right: 0.21rem;
  }
  .ship-content{
    flex: 1;
    text-align: left;
    margin-right: .32rem;
    color: #222;
    font-size: 12px;
    overflow: hidden;
    &_activity {
      color: @sui_color_white;
    }
    .tips {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-word;
    }
    .float-count-down {
      position: absolute;
      right: 0rem;
      top: -0.34rem;
    }
  }
  .cart-tip__operation {
    text-decoration: none;
    font-weight: bold;
    color: #222;
    white-space: nowrap;
    .font-dpr(24px);
    .margin-l(0.32rem);
  }
}

.freedisappear-leave-active {
  transition: all 2.5s cubic-bezier(1,0,1,0);
}
.freedisappear-leave-to {
  opacity: 0.85;
}
</style>
