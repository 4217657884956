<template>
  <div
    v-if="isShowGetCoupon"
    class="get-coupon-wrapper"
    da-event-click="1-8-2-7"
    :data-mall_code="mallCode"
    :data-store_code="storeCode"
    @click="showGetStoreCoupon"
  >
    {{ language.SHEIN_KEY_PWA_20957 }}
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import { getStoreCouponState } from '../../../utils/fetcher'
export default {
  name: 'GetCoupon',
  props: {
    mallCode: { // mall编码
      type: [String, Number],
      default: ''
    },
    storeCode: { // 店铺编码
      type: [String, Number],
      default: ''
    },
    storeType: { // 店铺类型: 1-平台类型, 2-自营类型, 3-二手类型
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      isShowGetCoupon: false
    }
  },
  computed: {
    ...mapGetters('cart', ['carts']),
    ...mapState('cart', ['language', 'cartAbtInfo', 'isPlatForm', 'isReflashGetCouponFetch'])
  },
  watch: {
    isReflashGetCouponFetch (val) {
      if (val) {
        this.init()
      }
    },
    isShowGetCoupon (val) {
      if (val) {
        daEventCenter.triggerNotice({ daId: '1-8-2-6', extraData: {
          mall_code: this.mallCode,
          store_code: this.storeCode
        } })
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapMutations('cart', ['updateState']),
    init () {
      if (!this.isPlatForm) return
      if (this.storeType != '1') return
      if (this.cartAbtInfo?.CartGetCoupons?.param == 'request=yes&show=yes') {
        this.getState()
      }
    },
    // 打开领券弹窗
    showGetStoreCoupon () {
      const products = []
      this.carts.map(item => {
        if (item.store_code == this.storeCode) {
          products.push({
            skc: item?.product?.goods_sn || ''
          })
        }
      })
      this.updateState({
        key: 'getCouponMallInfo',
        value: {
          mall_code: this.mallCode,
          store_code: this.storeCode,
          products
        }
      })
      this.updateState({ key: 'drawerGetCoupon', value: true })
    },
    // 获取是否展示领券入口状态
    async getState () {
      // 是否需要登录判断
      const res = await getStoreCouponState({
        mall_code: this.mallCode,
        store_code: this.storeCode,
        products: this.carts.map(item => {
          return {
            skc: item?.product?.goods_sn || ''
          }
        })
      })
      if (res.code == '0') {
        if (res?.info?.store?.length) {
          this.isShowGetCoupon = res?.info?.store[0]?.state == '1'
        }
      }
      this.updateState({
        key: 'isReflashGetCouponFetch',
        value: false
      })
    }
  }
}
</script>

<style lang="less">
.get-coupon-wrapper {
  position: relative;
  color: #2D68A8;
  cursor: pointer;
  margin-left: 16/75rem;
}
</style>
