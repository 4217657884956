const { IS_RW } = gbCommonInfo

function bindContainerScrollListener () {
  /**
   * 吸顶提示
   * 活动类模块的吸顶优先级：全场满赠（仅限于满足门槛但未选赠品且没有赠品外露的场景）>免邮活动（首单免邮/普通免邮/阶梯运费且展示在头部模块的场景）>全场加价购（仅限于满足门槛但未选换购品的场景）
   * 若全场满赠（场景同上）、免邮活动（场景同上）、全场加价购（场景同上）3个模块，在购物车内同时存在2个或3个，则按照该优先级，决定哪个模块吸顶
   *
   * */
  const handleStickyEle = (targetEle) => {
    if($('.ele-wrapper', targetEle).hasClass('sticky-block')){
      $('.ele-wrapper', targetEle).removeClass('sticky-block').css({ top: 0 } )
      $('.j-top-act-height', targetEle).css('height', 0)
    }
  }
  const fullGiftSticky = () => {
    const targetFullGift = document.querySelector(`.j-top-full-gift`)
    const targetShipping = document.querySelector(`.j-top-shipping`)
    const targetAddOn = document.querySelector(`.j-top-add-on`)
    const target = targetFullGift || targetShipping || targetAddOn
    if (!target) return $('.j-top-act-height').css('height', 0)
    if(targetFullGift && (targetShipping || targetAddOn)){
      targetShipping && handleStickyEle(targetShipping)
      targetAddOn && handleStickyEle(targetAddOn)
    } else if(targetShipping && targetAddOn){
      handleStickyEle(targetAddOn)
    }

    let originEleTopHeight = target.getBoundingClientRect().top
    const cartHeaderHeight = $('.j-cart-header').height()
    const targetHeight = $('.ele-wrapper', target).height()
    let topLoginHeight = 0
    // if($('.j-top-login').length){
    //   topLoginHeight = $('.j-top-login').height()
    // }
    // console.log(originEleTopHeight + ' ' + cartHeaderHeight)
    if (originEleTopHeight - cartHeaderHeight - topLoginHeight + 1 > 0) {
      $('.ele-wrapper', target).removeClass('sticky-block').css({ top: 0 } )
      $('.j-top-act-height', target).css('height', 0)
    } else {
      $('.j-top-act-height', target).css('height', targetHeight + 'px')
      $('.ele-wrapper', target).addClass('sticky-block').css({ top: ( cartHeaderHeight + topLoginHeight - 1 ) + 'px' } )
    }
  }

  // 优惠券助手悬浮窗
  const animateStart = (configs, duration = 361) => {
    configs.forEach(item => {
      $(item.attr).animate(item.style, duration, 'linear')
    })
  }
  let lastTimeScrollTop = 0
  const setCouponHelperBehavior = () => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    if ((lastTimeScrollTop && scrollTop) || (lastTimeScrollTop <= 0 && scrollTop <= 0)) return lastTimeScrollTop = scrollTop
    lastTimeScrollTop = scrollTop
    if (scrollTop == 0) {
      if ($('.is-old-style')?.length) {
        $('.j-coupon-helper-bubble').removeClass('bubble-fold')
      } else {
        animateStart([
          { attr: '.wrapper-right', style: { opacity: 1 } },
          { attr: '.circle-progress', style: { opacity: 0 } },
          { attr: '#coupon-helper-wrapper', style: { width: '100%', height: IS_RW ? `${82 / 75}rem` : `${86 / 75}rem` } },
          { attr: '.wrapper-left', style: { width: '1rem', height: '1rem', 'border-width': IS_RW && '1px' } },
          { attr: '.rw_wrapper', style: { 'border-width': '1px', 'left': '-1px' } },
          { attr: '.red-dot', style: { width: `${8 / 75}rem`, height: `${8 / 75}rem`, top: '20%' } },
          { attr: '.coupon-icon', style: { fontSize: '20px', transform: 'rotate(0)', top: '0', left: '0' } },
          { attr: '.hide-red-dot', style: { top: IS_RW ? `${4 / 75}rem` : `${8 / 75}rem` } },
          { attr: '.show-red-dot', style: { top: '0' } }
        ])
        // $('.circle-progress')[0]?.__vue__ 这样可以获取进度条的组件
      }
    } else {
      if ($('.is-old-style')?.length) {
        $('.j-coupon-helper-bubble').addClass('bubble-fold')
      } else {
        animateStart([
          { attr: '.wrapper-right', style: { opacity: 0 } },
          { attr: '.circle-progress', style: { opacity: 1 } },
          { attr: '#coupon-helper-wrapper', style: { width: `${68 / 75}rem`, height: `${68 / 75}rem` } },
          { attr: '.wrapper-left', style: { width: '.8rem', height: '.8rem', 'border-width': IS_RW && '0px' } },
          { attr: '.rw_wrapper', style: { 'border-width': '0px', 'left': '0' } },
          { attr: '.red-dot', style: { width: `${6 / 75}rem`, height: `${6 / 75}rem`, top: '18%' } },
          { attr: '.coupon-icon', style: { fontSize: '18px', transform: 'rotate(-30deg)', top: `${-8 / 75}rem`, left: `${-2 / 75}rem` } },
          { attr: '.hide-red-dot', style: { top: `${-2 / 75}rem` } },
          { attr: '.show-red-dot', style: { top: `${-8 / 75}rem` } }
        ])
      }
      
    }
  }

  const handleScroll = () => {
    if (!this.$router) return 
    const routerName = this.$router.currentRoute.name
    const switching = this.$router.switching

    // 路由切换时 or 路由非cart时 return
    if(switching || routerName !== 'cart') return

    if(this.carts?.length){
      fullGiftSticky()
      setCouponHelperBehavior()
    }
  }
  window.onscroll = handleScroll
}

export {
  bindContainerScrollListener
}
