<template>
  <div
    class="qs-tag__wrapper"
    :style="{ fontSize: exceed1Line ? 20/75 + 'rem' : 24/75 + 'rem' }"
  >
    <sui_icon_qucikship_flat_24px
      size="16px"
      class="qs-tag__icon"
    />
    <span class="qs-tag__text">{{ cartQsTagInfo.text }}</span>
    <span class="divider"></span>
    <span
      ref="textElement"
      class="qs-tag__tip"
    >{{ cartQsTagInfo.qsTimelinessInfo.tip }}</span>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { sui_icon_qucikship_flat_24px } from '@shein-aidc/icon-vue2'

export default {
  name: 'CartQuickShipTop',
  components: {
    sui_icon_qucikship_flat_24px,
  },
  data() {
    return {
      textHeight: null,
      textLineHeight: null,
    }
  },
  computed: {
    ...mapState('cart', ['cartQsTagInfo']),
    exceed1Line() {
      return this.textHeight > this.textLineHeight
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.calcTextHeight()
    })
  },
  methods: {
    // 计算内容高度，超过一行字体缩小为10px
    calcTextHeight() {
      const textElement = this.$refs.textElement
      this.textHeight = textElement?.clientHeight
      this.textLineHeight = parseFloat(window?.getComputedStyle(textElement)?.lineHeight)
    }
  },
}
</script>

<style lang="less" scoped>
.qs-tag__wrapper {
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding: 20/75rem 24/75rem;
  background: #ECFCF3;
  color: #198055;
  line-height: 34/75rem;
  .qs-tag__icon {
    margin-right: 4/75rem;
  }
  .qs-tag__text {
    font-style: italic;
    font-weight: 590;
  }
  .divider {
    display: inline-block;
    width: 1/75rem;
    height: 24/75rem;
    background: rgba(25, 128, 85, 0.40);
    margin: 0 16/75rem;
  }
  .qs-tag__tip {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    text-overflow: ellipsis;
    font-style: normal;
    font-weight: 400;
    color: #198055;
  }
}
</style>
