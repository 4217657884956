<template>
  <div class="voucher-filter-tip">
    <div
      class="voucher-tip-content"
      v-html="tipContent"
    >
    </div>
    <Icon
      name="sui_icon_close_16px_2"
      size="16px"
      @click="handleClose"
    />
  </div>
</template>
<script setup>
import { defineProps, defineExpose, defineEmits } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'

defineProps({
  tipContent: {
    type: String,
    default: ''
  },
})
const emits = defineEmits(['close'])
function handleClose() {
  emits('close')
}
defineExpose({
  
})
</script>
<style lang="less" scoped>
.voucher-filter-tip {
  display: flex;
  align-items: center;
  gap: 20/75rem;
  position: relative;
  margin: 16/75rem 12/75rem -16/75rem;
  padding: 2/75rem 24/75rem;
  border-radius: 8/75rem 8/75rem 0px 0px;
  background: linear-gradient(72deg, rgba(255, 255, 255, 0.20) 75%, rgba(236, 215, 253, 0.20) 95.75%), linear-gradient(180deg, #E5F1FF -21.59%, #F7FAFF 100%);
  box-shadow: 0px 1px 1px 0px #FFF inset;
  color: #959595;
  .voucher-tip-content {
    margin: 16/75rem 0;
    font-size: 12px;
    color: #767676;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }
}
</style>
