<template>
  <div
    class="discount-tag"
    :class="[ primeClassName ]"
    @click="onClick"
  >
    <span class="discount">
      {{ discountText }}
    </span>
    <template v-if="!isSoldout">
      <template v-if="showDiscountCountdown">
        <i class="line">|</i>
        <count-down-time
          font-size="10px"
          not-hide
          not-reload
          :time-stamp="timeStamp"
        />
      </template>
      <template v-if="discountDetailSwitch">
        <Icon
          name="sui_icon_more_right_16px"
          size="14px"
          :color="isPremiumMember ? primeColor : '#FA6338'"
          class="discount-tag__more-right"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue2'
import { mapState, mapGetters, mapMutations } from 'vuex'
import { getTimeStamp, canShowCountdown } from '@/public/src/pages/cartNew/utils/estimated.js'

export default {
  name: 'DiscountTag',

  components: {
    Icon,
  },

  props: {
    item: {
      type: Object,
      default: () => {}
    },
    discountText: {
      type: String,
      default: ''
    },
  },

  computed: {
    ...mapState('cart', ['batchActive', 'integrityPromotionInfo', 'soldoutIds']),
    ...mapGetters('cart', ['showCartPromotionsDetail', 'cartDiscountLogoA']),
    canShowCountdown() {
      return canShowCountdown(this.item, null, this.integrityPromotionInfo, 'DISCOUNT')
    },
    timeStamp() {
      // 折扣明细倒计时券不参与计算
      return getTimeStamp(this.item, null, this.integrityPromotionInfo, 'DISCOUNT')
    },
    primeColor() {
      return this.cartDiscountLogoA ? '#873C00' : '#C96E3F'
    },
    isPremiumMember(){
      return this.item.promotionTypeIds.includes(29)
    },
    primeClassName() {
      return this.isPremiumMember
        ? this.cartDiscountLogoA ? 'prime-style-a' : 'prime-style-b'
        : ''
    },
    showDiscountCountdown() {
      return this.showCartPromotionsDetail && this.canShowCountdown
    },
    discountDetailSwitch() {
      return this.showCartPromotionsDetail && !this.batchActive
    },
    isSoldout() {
      return this.soldoutIds.indexOf(this.item.id) > -1
    }
  },
  methods: {
    ...mapMutations('cart', ['changeEstimatedPriceDrawerParams']),

    onClick() {
      if (!this.discountDetailSwitch || this.isSoldout) return
      this.changeEstimatedPriceDrawerParams({
        show: true,
        cartItem: this.item,
        type: 'DISCOUNT'
      })
      
    }
  },
}
</script>

<style lang="less" scoped>
.discount-tag {
  display: inline-flex;
  max-width: 100%;
  align-items: center;
  height: 18/37.5rem;
  padding: 2/37.5rem 4/37.5rem;
  margin: 4/75rem 0;
  color: #FA6338;
  line-height: normal;
  background: linear-gradient(90deg, #FFECE9 2.73%, #FFFAF9 100%), linear-gradient(90deg, #FDF4D0 0.02%, rgba(253, 244, 208, 0.30) 100.8%);
  .discount {
    font-size: 10/37.5rem;
    font-style: normal;
    font-weight: 500;
    color: #FA6338;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .line {
    flex-shrink: 0;
    display: inline-block;
    width: 4/37.5rem;
    color: #FDB9A6;
    text-align: center;
    font-family: "SF UI Text";
    font-size: 10/37.5rem;
    font-style: normal;
    font-weight: 400;
    margin: 0 4/37.5rem;
  }
  .new-down-time {
    flex-shrink: 0;
    line-height: 0;
    /deep/.count-number {
      height: 28/75rem;
      line-height: 28/75rem;
      padding: 0 4/75rem;
    }
  }
  &__more-right {
    flex-shrink: 0;
    margin-left: 2/75rem;
    transform: rotateY(0deg)/* rtl: rotateY(180deg) */;
  }
}
.discount-tag.prime-style-a {
  color: #873C00;
  .discount {
    color: #873C00;
  }
  .line {
    color: #873C00;
    opacity: 0.4;
  }
}
.discount-tag.prime-style-b {
  color: #C96E3F;
  .discount {
    color: #C96E3F;
  }
  .line {
    color: #C96E3F;
    opacity: 0.4;
  }
}
</style>
