<template>
  <div
    ref="marquee"
    :style="style"
    class="text-marquee"
  >
    <div
      ref="text"
      :class="['text-marquee__text', isAnimation && 'text-marquee__text_animation']"
    >
      <slot>{{ content }}</slot>
    </div>
  </div>
</template>

<script>
/**
 * @description 文字跑马灯效果
 * @example <TextMarquee>内容</TextMarquee>
 */
export default {
  name: 'TextMarquee',

  props: {
    // 跑马灯宽度
    width: {
      type: String,
      default: '68px',
    },
    // 跑马灯速度
    speed: {
      type: Number,
      default: 30,
    },
    // 跑马灯内容
    content: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isAnimation: false,
      duration: 0,
      textWidth: '',
    }
  },

  computed: {
    style() {
      return {
        '--width': `${this.width}`,
        '--duration': `${this.duration}s`,
        '--text-width': this.textWidth ? `${this.textWidth}px` : 'auto',
      }
    },
  },

  mounted() {
    this.init()
  },

  methods: {
    init() {
      this.$nextTick(() => {
        this.textWidth = this.$el.scrollWidth
        this.isAnimation = this.textWidth > this.$el.clientWidth
        this.duration = (this.textWidth / this.speed).toFixed(2)

        // CSS VAR 兼容处理
        if (!window?.CSS?.supports('--a', 0)) {
          this.$refs.marquee.style.cssText = `max-width: ${this.style['--width']};`
          this.$refs.text.style.cssText = this.isAnimation
            ? `width: ${this.style['--text-width']}; animation-duration: ${this.style['--duration']}`
            : `width: ${this.style['--text-width']}`
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.text-marquee {
  overflow: hidden;
  max-width: var(--width, 100%);
  &__text {
    white-space: nowrap;
    width: var(--text-width, auto);
    &_animation {
      animation-name: marquee;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-duration: var(--duration, 3s);
    }
  }
}
@keyframes marquee {
  0% {
    transform: translateX(0) /* rtl: translateX(-100) */;
  }
  100% {
    transform: translateX(-100%) /* rtl: translateX(0) */;
  }
}
</style>
