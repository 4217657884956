var render = function render(){var _vm=this,_c=_vm._self._c;return _c('s-popover',{attrs:{"trigger-type":"user","placemen":"top-end","show-close-icon":_vm.showFilterLabelPopover == 'incentive',"prop-style":{
    position: 'fixed',
    zIndex: 30,
    right: _vm.GB_cssRight ? 'auto' : '0.8rem',
    left: _vm.GB_cssRight ? '0.8rem' : 'auto',
    ..._vm.getIncentivePopoverStyle(),
  }},on:{"handleCloseIconClick":_vm.handleCloseIconClick},model:{value:(_vm.isShowPopover),callback:function ($$v) {_vm.isShowPopover=$$v},expression:"isShowPopover"}},[_c('div',{class:_vm.popoverContentId}),_vm._v(" "),_c('div',{class:[
      'checkout-btn-contain',
      _vm.showFilterLabelPopover == 'incentive' ? 'sweep-light' : ''
    ],attrs:{"slot":"reference"},slot:"reference"},[_vm._t("default"),_vm._v(" "),_c('ClientOnly',[_c('CustomTeleport',{ref:"customTeleport"},[(_vm.showFilterLabelPopover == 'filter')?_c('span',[_vm._v(_vm._s(_vm.language.SHEIN_KEY_PWA_23995))]):(_vm.showFilterLabelPopover == 'incentive')?_c('IncentivePopoverContent',{ref:"incentivePopoverContentRef"}):_vm._e()],1)],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }