var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.render)?_c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
    id: '1-8-3-47',
    data: {
      sku_code: _vm.item.id,
      is_checked: _vm.item.is_checked,
      is_change: _vm.isChange,
      scene: _vm.showIncentiveProductListDialog ? 'benefit_pop' : 'cart'
    },
  }),expression:"{\n    id: '1-8-3-47',\n    data: {\n      sku_code: item.id,\n      is_checked: item.is_checked,\n      is_change: isChange,\n      scene: showIncentiveProductListDialog ? 'benefit_pop' : 'cart'\n    },\n  }"}],ref:"target",staticClass:"estimated-tag j-estimated-price",class:[
    _vm.primeClassName,
  ],attrs:{"data-id":_vm.item.id},on:{"click":_vm.onClick}},[_c('span',{staticClass:"estimated"},[_vm._v("\n    "+_vm._s(_vm.estimatedText)+"\n  ")]),_vm._v(" "),(_vm.showDiscount)?_c('span',{staticClass:"discount"},[_vm._v("\n    "+_vm._s(_vm.discountText)+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.isShowCartrowEstimatedCountdown && _vm.canShowCountdown)?[_c('i',{staticClass:"line"},[_vm._v("|")]),_vm._v(" "),_c('count-down-time',{attrs:{"font-size":"10px","not-hide":"","not-reload":"","time-stamp":_vm.timeStamp}})]:_vm._e(),_vm._v(" "),(_vm.estimatedDetailSwitch)?[_c('Icon',{staticClass:"estimated-tag__more-right",attrs:{"name":"sui_icon_more_right_16px","size":"14px","color":_vm.isPremiumMember ? _vm.primeColor : '#FA6338'}})]:_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }