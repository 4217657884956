<script setup>
import { ref, onMounted } from 'vue'
import {
  Dialog as SDialog,
  Button as SButton,
} from '@shein/sui-mobile'
import useDeleteTips from '../../hooks/useDeleteTips'

const imgSrc = ref('')
onMounted(() => {
  const { PUBLIC_CDN = '' } = gbCommonInfo || {}
  imgSrc.value = `${PUBLIC_CDN}/pwa_dist/images/cart/delete_tips_empty_cart-aef2fe47b0.png`
})

const {
  isShowDeleteTips,
  deleteTipsText,
  confirmBtnText,
  deleteBtnText,
  onClickConfirm,
  onClickDelete,
  onClose,
} = useDeleteTips()
</script>

<template>
  <SDialog
    :visible.sync="isShowDeleteTips"
    show-close
    :close-on-click-modal="false"
    class="find-similar-tips"
    @close-from-icon="onClose"
  >
    <template #top>
      <img
        :src="imgSrc"
        alt="empty-cart"
        class="find-similar-tips__img"
      />
    </template>
    <div class="find-similar-tips__content">
      {{ deleteTipsText }}
    </div>
    <template #footer>
      <SButton
        :type="['primary', 'H72PX']"
        width="100%"
        @click="onClickConfirm"
      >
        {{ confirmBtnText }}
      </SButton>
      <div
        class="find-similar-tips__delete"
        @click="onClickDelete"
      >
        {{ deleteBtnText }}
      </div>
    </template>
  </SDialog>
</template>

<style lang="less" scoped>
.find-similar-tips {
  &__img {
    margin-top: 30/37.5rem;
    height: 75/37.5rem;
    object-fit: contain;
  }
  &__content {
    margin: 0 0 8/37.5rem;
    color: @sui_color_gray_dark1;
    font-size: 14/37.5rem;
    text-align: center;
  }
  &__delete {
    margin-top: 12/37.5rem;
    color: @sui_color_link;
    font-size: 14/37.5rem;
    line-height: 17/37.5rem;
  }
}
</style>
