<template>
  <s-popover
    v-model="isShowPopover"
    trigger-type="user"
    placemen="top-end"
    :show-close-icon="showFilterLabelPopover == 'incentive'"
    :prop-style="{
      position: 'fixed',
      zIndex: 30,
      right: GB_cssRight ? 'auto' : '0.8rem',
      left: GB_cssRight ? '0.8rem' : 'auto',
      ...getIncentivePopoverStyle(),
    }"
    @handleCloseIconClick="handleCloseIconClick"
  >
    <div :class="popoverContentId"></div>
    <div
      slot="reference"
      :class="[
        'checkout-btn-contain',
        showFilterLabelPopover == 'incentive' ? 'sweep-light' : ''
      ]"
    >
      <slot></slot>
      
      <ClientOnly>
        <CustomTeleport ref="customTeleport">
          <span v-if="showFilterLabelPopover == 'filter'">{{ language.SHEIN_KEY_PWA_23995 }}</span>
          <IncentivePopoverContent
            v-else-if="showFilterLabelPopover == 'incentive'"
            ref="incentivePopoverContentRef"
          />
        </CustomTeleport>
      </ClientOnly>
    </div>
  </s-popover>
</template>

<script>
import { nanoid } from 'nanoid'
import { mapState, mapGetters, mapMutations } from 'vuex'
import IncentivePopoverContent from './cartCheckoutIncentive/IncentivePopoverContent.vue'
import CustomTeleport from './cartCheckoutIncentive/CustomTeleport.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
daEventCenter.addSubscriber({ modulecode: '1-8-3' })
const { GB_cssRight } = gbCommonInfo

/**
 * @description 购物车/结算BAR/结算按钮Popover组件
 */
export default {
  name: 'CartCheckoutButtonPopover',
  components: {
    CustomTeleport,
    IncentivePopoverContent,
  },
  props: {
    showFilterLabelPopover: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      GB_cssRight,
      isShowPopover: false,
      popoverContentId: `j-cart-checkout-${nanoid()}`
    }
  },

  computed: {
    ...mapState('cart', ['language', 'incentivePopoverSwitchTimer', 'incentivePopoverSwitchTimer', 'incentivePopoverKeepTimer', 'showIncentiveTimes', 'showIncentivePopover', 'inducementSortList', 'incentivePopoverContent']),
    ...mapGetters('cart', ['incentiveTimeConfig'])
  },
  watch: {
    showFilterLabelPopover(n, o) {
      if(n) {
        this.isShowPopover = true
        setTimeout(() => {
          this.$refs['customTeleport']?.teleport(`.${this.popoverContentId}`)
        }, 0)
      } else {
        this.isShowPopover = false
      }
      console.log(`debugger, showFilterLabelPopover, ${n}, ${o},popover ${this.isShowPopover}`)
    }
  },
  methods: {
    ...mapMutations('cart', ['updateState']),
    getIncentivePopoverStyle() {
      return {
        bottom: '64px',
        top: 'unset',
        position: 'fixed',
        right: this.GB_cssRight ? 'auto' : '0.32rem',
        left: this.GB_cssRight ? '0.32rem' : 'auto',
      }
    },
    handleCloseIconClick() {
      if(this.showFilterLabelPopover != 'incentive') return
      if(this.incentivePopoverKeepTimer) {
        clearTimeout(this.incentivePopoverKeepTimer)
        this.updateState({
          key: 'incentivePopoverKeepTimer',
          value: null
        })
        this.updateState({
          key: 'showIncentivePopover',
          value: false
        })
      }
      if(this.showIncentiveTimes < 3 && (this.inducementSortList?.length || 0) - 1 >= this.showIncentiveTimes) {
        const incentiveGapTime = this.incentiveTimeConfig?.incentiveGapTime || 1000 * 60
        if(this.incentivePopoverSwitchTimer) {
          clearTimeout(this.incentivePopoverSwitchTimer)
          this.updateState({
            key: 'incentivePopoverSwitchTimer',
            value: null
          })
        }
        const gapTimer = setTimeout(() => {
          this.updateState({
            key: 'showIncentivePopover',
            value: true
          })
        }, incentiveGapTime)
        this.updateState({
          key: 'incentivePopoverSwitchTimer',
          value: gapTimer
        })
      }
      daEventCenter.triggerNotice({
        daId: '1-8-3-61',
        extraData: {
          actual_point: this.incentivePopoverContent?.type,
          ...this.getExtraAnalysisData(this.incentivePopoverContent),
        }
      })
    },
    getExtraAnalysisData(content) {
      if(content.type == 'lowest_price_30days' && content?.actualPointLowest) {
        return {
          actual_point_lowest: content?.actualPointLowest
        }
      } else {
        return {}
      }
    }
  }
}
</script>

<style lang="less">
.checkout-btn-contain {
  position: relative;
}
</style>
