<template>
  <div>
    <s-alert
      v-if="cartNoticeMessage"
      class="j-top-notice cart-top-notice"
      type="info"
      :closable="true"
      :show-icon="true"
      :rtl-icon-roate="true"
      :load-more-txt="language['SHEIN_KEY_PWA_15698']"
      :description="cartNoticeMessage"
      @close="close"
      @onLoadMoreClick="onLoadMoreClick"
    >
      <!-- <div v-html="cartNoticeMessage" class="notice-content"></div> -->
    </s-alert>
    <s-dialog
      :visible.sync="isShowNotic"
      :show-close="false"
      :append-to-body="true"
      :close-on-click-modal="false"
    >
      <div v-html="cartNoticeMessage">
      </div>
      <template slot="footer">
        <s-button
          :type="['primary']"
          :width="'100%'"
          @click="noticClosehandle"
        >
          {{ language['SHEIN_KEY_PWA_15312'] }}
        </s-button>
      </template>
    </s-dialog>
  </div>
</template>

<script>
import { daEventCenter } from '../../../services/eventCenter'
import { mapState, mapMutations } from 'vuex'
import schttp from 'public/src/services/schttp'

export default {
  data () {
    return {
      message: '',
      isShowNotic: false
    }
  },
  computed: {
    ...mapState('cart', ['language', 'cartNoticeMessage'])
  },
  watch: {
    cartNoticeMessage: {
      handler (n) {
        if(n){
          daEventCenter.triggerNotice({ daId: '1-8-1-10' })
        }
      },
      immediate: true
    }
  },
  mounted () {
    if(!this.cartNoticeMessage) {
      this.getCartNotice()
    }

  },
  methods: {
    ...mapMutations('cart', ['updateState']),
    onLoadMoreClick () {
      this.isShowNotic = true
      daEventCenter.triggerNotice({ daId: '1-8-1-12' })
    },
    getCartNotice () {
      if(this.cartNoticeMessage){
        return Promise.resolve({})
      }
      return schttp({
        url: '/api/cart/communalNoticeCart/get'
      }).then( res => {
        if (res.code == 1000) {
          this.updateState({ key: 'cartNoticeMessage', value: res?.data?.content || '' })
        }
        return true
      })
    },
    close () {
      $(window).scroll()
      daEventCenter.triggerNotice({ daId: '1-8-1-11' })
    },
    noticClosehandle () {
      this.isShowNotic = false
    }
  }
}
</script>
<style lang="less">
.cart-top-notice{
  padding-top: 20/75rem;
  padding-bottom: 20/75rem;
  max-height: 1.92rem;
  overflow: hidden;
}
.is-ssr{
  .cart-top-notice{
    .notice-content{
      max-height: 1.38rem;
      overflow: hidden;
      word-break: break-word;
    }
  }
}
</style>
