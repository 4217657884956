<script lang="jsx">

const findCartListInstance = (vComp) => {
  if (vComp?.$options.name != 'CartList') {
    return findCartListInstance(vComp?.$parent)
  }
  return vComp
}

function findComponentByName(rootInstance, componentName) {
  let foundComponent = null

  // 定义一个递归函数来深度优先搜索组件树
  function searchComponent(subComponent) {
    // 检查当前组件的名称是否匹配
    if (subComponent?.$options?.name === componentName) {
      foundComponent = subComponent
      return
    }
    // 如果找到了，就不再继续递归
    if (foundComponent) return

    // 否则，遍历所有子组件，并对每个子组件递归调用此函数
    subComponent?.$children?.forEach((childComponent) => {
      searchComponent(childComponent)
    })
  }

  // 从根实例开始递归搜索
  searchComponent(rootInstance)

  // 返回找到的组件实例，或者如果没有找到，则为null
  return foundComponent
}

export default {
  name: 'CartItemCheckbox',
  functional: true,
  props: {
    item: {
      type: Object,
      required: true
    },
    batchActive: {
      type: Boolean,
      default: false
    },
    batchEditItemIds: {
      type: Array,
      default: () => []
    },
    isCheckedByLocal: {
      type: Object,
      default: () => ({})
    },
    config: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    toggle() {
      this.$emit('toggle')
    }
  },
  render(h, { props, parent }) {
    const { item, batchEditItemIds, batchActive, isCheckedByLocal, config } = props
    let cartListInstance = null
    if(config?.fromDialog) {
      // eslint-disable-next-line no-undef
      if(typeof window !== 'undefined') cartListInstance = findComponentByName(CartVue, 'CartList')
    } else {
      cartListInstance = findCartListInstance(parent)
    }

    // 优先使用存在本地缓存的选中状态
    const checkedStatus = typeof isCheckedByLocal[item.id] === 'number' ? Boolean(isCheckedByLocal[item.id]) : Boolean(item.is_checked)

    // updateBatchEditItemIds
    return (
      <div 
        class={[
          'checkbox-item',
          'new-checkbox-item'
        ]}
      >
       
        <SCheckboxGroup
          v-show={batchActive}
          value={batchEditItemIds}
          class="batch-checkbox"
          vOn:change={v => console.log(v)}
        >
          <div vOn:click={parent.handleClickItemBatchCheck}>
            <SCheckbox
              label={item.id}
              disabled={item.isNewcomerItem}
              margin-r={20 / 75 + 'rem'}
              vOn:change={parent.handleItemBatchCheck}
            />
          </div>
        </SCheckboxGroup>
        <div
          v-show={!batchActive}
          class="newcomer-guide__checkbox"
          style={{ opacity: item.isGiftItem ? 0 : 1 }}
        >
          <SCheckbox
            label={item.id}
            disabled={item.isDisabled}
            value={checkedStatus}
            margin-r={20 / 75 + 'rem'}
            vOn:change={cartListInstance.handleCheckedItem}
          />
        </div>
      </div>
    )
  }
}

</script>
